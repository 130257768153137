import config from './config'

const environment = config('env') as string

export const sentryOptions = {
    dsn: process.env.SENTRY_DSN as string,
    tracesSampleRate: 0.5,
    debug: environment === 'local',
    // release: process.env.GIT_REF,
    environment,
    tracesSampler: (context: any) => {
        if (
            /^\/learn\/(courses|podcasts|webinars)\/[0-9]+/.test(
                context.location?.pathname || ''
            )
        ) {
            // reduce rate due to the progress updates when a video/audio is playing
            return 0.01
        }
        return 0.5
    }
}
