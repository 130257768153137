import { ElementType, useCallback } from 'react'
import { Typography, Box } from '@mui/material'
import { ExtendedTheme, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { ifEnter } from '../dom'
import parse, {
    HTMLReactParserOptions,
    Element,
    domToReact,
    attributesToProps
} from 'html-react-parser'
import {
    wrapBlockquoteTag,
    wrapTDTag,
    wrapTHTag,
    wrapTableTag,
    wrapTodoList,
    wrapFigcaptionTag,
    wrapH1Tag,
    wrapH2Tag,
    wrapH3Tag,
    wrapH4Tag,
    wrapH5Tag,
    wrapH6Tag,
    wrapTodoListLabel,
    wrapTodoListLabelDescription,
    wrapTableContainer,
    wrapFigureTag,
    wrapImgTag,
    wrapEmbeddedThriveVideo
} from './wrappers'
import {
    isCmsTodoListLabelDescription,
    isCmsTodoListLabel,
    isCmsTodoList,
    isCmsTableContainer
} from './utils'

export default function useParseHtml() {
    const theme = useTheme()

    const parseHtml = useCallback(
        (html: any, onLinkClick: (url: string) => void = () => undefined) => {
            const handleOnClick = (url: string) => () => onLinkClick(url)

            const option: HTMLReactParserOptions = {
                replace: (domNode) => {
                    if (domNode instanceof Element) {
                        if (domNode.name === 'a') {
                            return (
                                <Box
                                    onClick={handleOnClick(
                                        domNode.attribs.href
                                    )}
                                    onKeyUp={ifEnter(
                                        handleOnClick(domNode.attribs.href)
                                    )}
                                    tabIndex={0}
                                    component="div"
                                    color={theme.palette.accent.main}
                                    sx={{
                                        cursor: 'pointer',
                                        display: 'inline'
                                    }}
                                >
                                    {domToReact(domNode.children, option)}
                                </Box>
                            )
                        }

                        if (isCmsTodoList(domNode)) {
                            return wrapTodoList(domNode, option, theme)
                        }
                        if (isCmsTodoListLabel(domNode)) {
                            return wrapTodoListLabel(domNode, option, theme)
                        }
                        if (isCmsTodoListLabelDescription(domNode)) {
                            return wrapTodoListLabelDescription(
                                domNode,
                                option,
                                theme
                            )
                        }

                        if (isCmsTableContainer(domNode)) {
                            return wrapTableContainer(domNode, option, theme)
                        }
                        if (domNode.name === 'table') {
                            return wrapTableTag(domNode, option, theme)
                        }
                        if (domNode.name === 'th') {
                            return wrapTHTag(domNode, option, theme)
                        }
                        if (domNode.name === 'td') {
                            return wrapTDTag(domNode, option, theme)
                        }
                        if (domNode.name === 'figcaption') {
                            return wrapFigcaptionTag(domNode, option, theme)
                        }

                        if (domNode.name === 'blockquote') {
                            return wrapBlockquoteTag(domNode, option, theme)
                        }

                        if (domNode.name === 'h1') {
                            return wrapH1Tag(domNode, option, theme)
                        }
                        if (domNode.name === 'h2') {
                            return wrapH2Tag(domNode, option, theme)
                        }
                        if (domNode.name === 'h3') {
                            return wrapH3Tag(domNode, option, theme)
                        }
                        if (domNode.name === 'h4') {
                            return wrapH4Tag(domNode, option, theme)
                        }
                        if (domNode.name === 'h5') {
                            return wrapH5Tag(domNode, option, theme)
                        }
                        if (domNode.name === 'h6') {
                            return wrapH6Tag(domNode, option, theme)
                        }

                        if (domNode.name === 'figure') {
                            return wrapFigureTag(domNode, option, theme)
                        }

                        if (domNode.name === 'img') {
                            return wrapImgTag(domNode, option, theme)
                        }

                        if (domNode.name === 'oembed') {
                            return wrapEmbeddedThriveVideo(
                                domNode,
                                option,
                                theme
                            )
                        }
                    }
                }
            }

            return parse(html, option)
        },
        [theme]
    )

    return parseHtml
}
