import { useCallback, useMemo } from 'react'
import { useTransformBook } from './transformers'
import {
    Book,
    LearnContentStatusProgressType,
    useGetBookLazyQuery,
    useGetBooksQuery,
    useUpdateBookEpisodeStatusMutation
} from '../../graphql/generated/autogenerated'
import { ApolloError } from '@apollo/client'
import { TransformedBook } from './types'

export type UseBookResult = {
    book: TransformedBook | null
    isLoading: boolean
    getBook: (bookId: string) => void
    notFound: boolean
    isPristine: boolean
    error: Error | ApolloError | null
}

export const useFetchBook = (): UseBookResult => {
    const transformBook = useTransformBook()

    const [
        get,
        {
            loading,
            called,
            error = null,
            data = {
                content: {
                    thrive: {
                        book: {
                            getBook: null
                        }
                    }
                }
            }
        }
    ] = useGetBookLazyQuery({
        errorPolicy: 'all'
    })

    const getBook = useCallback(
        (bookId: string) => {
            get({
                variables: {
                    bookId
                }
            })
        },
        [get]
    )

    const book = useMemo(
        () =>
            data.content?.thrive?.book?.getBook
                ? transformBook(data.content?.thrive?.book?.getBook as Book)
                : null,
        [data.content?.thrive?.book?.getBook]
    )

    return {
        getBook,
        book,
        isLoading: loading,
        isPristine: !called,
        notFound: called && !loading && book === null,
        error
    }
}

interface UseBooks {
    books: Book[]
    noBooks: boolean
    hasMore: boolean
    isFetchingInitial: boolean
    isFetchingMore: boolean
    refetch: () => void
    fetchMore: () => void
    error?: ApolloError
}

export const useBooks = ({
    pageSize
}: { pageSize?: number } = {}): UseBooks => {
    pageSize = pageSize || 10

    const {
        refetch: refetchBooks,
        loading,
        data,
        fetchMore: fetchMoreBooks,
        error
    } = useGetBooksQuery({
        variables: { page: 1, pageSize },
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true
    })

    const hasMore = !!data?.content?.thrive?.book?.getBooks?.hasMore
    const currentPage = data?.content?.thrive?.book?.getBooks?.page || 1
    const nextPage = currentPage + 1

    const books = useMemo(
        () =>
            (
                (data?.content?.thrive?.book?.getBooks?.items || []) as Book[]
            ).filter((book) => !!book),
        [data?.content?.thrive?.book?.getBooks?.items]
    )

    const noBooks = !loading && books.length === 0

    const fetchMore = useCallback(() => {
        fetchMoreBooks({ variables: { pageSize, page: nextPage } })
    }, [nextPage, pageSize, fetchMoreBooks])

    const refetch = useCallback(() => {
        refetchBooks({ pageSize, page: 1 })
    }, [pageSize, refetchBooks])

    return {
        books,
        fetchMore,
        refetch,
        isFetchingInitial: loading && books.length === 0,
        isFetchingMore: loading && books.length > 0,
        hasMore,
        noBooks,
        error
    }
}

export const useUpdateBookEpisodeStatus = () => {
    const [updateEpisodeStatus, { data, loading }] =
        useUpdateBookEpisodeStatusMutation()

    const update = useCallback(
        (
            args: {
                contentId: string
                progressInSeconds: number
                totalInSeconds: number
                isComplete: boolean
            },
            refetchQueries: string[] = []
        ) => {
            const { contentId, progressInSeconds, totalInSeconds, isComplete } =
                args

            const progressType =
                LearnContentStatusProgressType[
                    isComplete ? 'Finished' : 'InProgress'
                ]

            updateEpisodeStatus({
                variables: {
                    input: {
                        contentId,
                        progressType,
                        progressInSeconds: Math.round(progressInSeconds),
                        totalInSeconds: Math.round(totalInSeconds)
                    }
                },
                refetchQueries
            })
        },
        [updateEpisodeStatus]
    )

    const partStatus = useMemo(() => {
        if (data?.content?.book?.audio?.updateAudioBookChapterPartStatus)
            return {
                ...data?.content?.book?.audio?.updateAudioBookChapterPartStatus
            }

        return null
    }, [data])

    return {
        update,
        partStatus,
        isUpdating: loading
    }
}
