import { IconName } from '@fortawesome/fontawesome-common-types'
import { CompanyResourceRevealedInfoType } from '../graphql/generated/autogenerated'

const getRevealedInfoIconName = (type: string): IconName | null => {
    switch (type) {
        case CompanyResourceRevealedInfoType.Call:
            return 'phone'
        case CompanyResourceRevealedInfoType.Email:
            return 'envelope'
        default:
            return null
    }
}

export default getRevealedInfoIconName
