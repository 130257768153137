import { ElementType } from 'react'
import { Box, Typography } from '@mui/material'
import {
    attributesToProps,
    domToReact,
    Element,
    HTMLReactParserOptions
} from 'html-react-parser'
import { ExtendedTheme } from '@thriveglobal/thrive-web-leafkit'
import { getThriveVideoIdFromEmbeddedTag } from './utils'
import { THRIVE_VIDEO_DOMAIN } from '../constants'

export function wrapBlockquoteTag(
    domNode: Element,
    option: HTMLReactParserOptions,
    theme: ExtendedTheme
) {
    const blockquoteProps = attributesToProps(domNode.attribs)

    return (
        <Box
            component={domNode.tagName as ElementType}
            sx={{
                overflow: 'hidden',
                px: theme.spacing(3),
                mx: 0,
                fontStyle: 'italic',
                borderLeft: `0.5rem solid ${theme.palette.grey[300]}`
            }}
            {...blockquoteProps}
        >
            {domToReact(domNode.children, option)}
        </Box>
    )
}

export function wrapTodoList(
    domNode: Element,
    option: HTMLReactParserOptions,
    theme: ExtendedTheme
) {
    const todoListProps = attributesToProps(domNode.attribs)

    return (
        <Box
            component={domNode.tagName as ElementType}
            sx={{
                listStyleType: 'none',
                pl: theme.spacing(2.5)
            }}
            {...todoListProps}
        >
            {domToReact(domNode.children, option)}
        </Box>
    )
}

export function wrapTodoListLabel(
    domNode: Element,
    option: HTMLReactParserOptions,
    theme: ExtendedTheme
) {
    const todoListLabelProps = attributesToProps(domNode.attribs)

    return (
        <Box
            component={domNode.tagName as ElementType}
            sx={{
                display: 'flex',
                alignItems: 'flex-start'
            }}
            {...todoListLabelProps}
        >
            {domToReact(domNode.children, option)}
        </Box>
    )
}

export function wrapTodoListLabelDescription(
    domNode: Element,
    option: HTMLReactParserOptions,
    theme: ExtendedTheme
) {
    const todoListLabelDescriptionProps = attributesToProps(domNode.attribs)

    return (
        <Box
            component={domNode.tagName as ElementType}
            sx={{
                pl: theme.spacing(0.625),
                lineHeight: '100%'
            }}
            {...todoListLabelDescriptionProps}
        >
            {domToReact(domNode.children, option)}
        </Box>
    )
}

export function wrapTableContainer(
    domNode: Element,
    option: HTMLReactParserOptions,
    theme: ExtendedTheme
) {
    const tableContainerProps = attributesToProps(domNode.attribs)

    return (
        <Box
            component={domNode.tagName as ElementType}
            sx={{
                display: 'table',
                m: 0
            }}
            {...tableContainerProps}
        >
            {domToReact(domNode.children, option)}
        </Box>
    )
}

export function wrapTableTag(
    domNode: Element,
    option: HTMLReactParserOptions,
    theme: ExtendedTheme
) {
    const tableProps = attributesToProps(domNode.attribs)

    return (
        <Box
            component={domNode.tagName as ElementType}
            sx={{
                borderCollapse: 'collapse',
                borderSpacing: '0',
                border: `0.1rem double ${theme.palette.grey[300]}`,
                width: '100%',
                height: '100%'
            }}
            {...tableProps}
        >
            {domToReact(domNode.children, option)}
        </Box>
    )
}

export function wrapTHTag(
    domNode: Element,
    option: HTMLReactParserOptions,
    theme: ExtendedTheme
) {
    const tableHeaderProps = attributesToProps(domNode.attribs)

    return (
        <Box
            component={domNode.tagName as ElementType}
            sx={{
                background: 'rgba(0,0,0,.05)',
                fontWeight: 'bold',
                p: theme.spacing(0.8),
                border: `0.1rem double ${theme.palette.grey[300]}`
            }}
            {...tableHeaderProps}
        >
            {domToReact(domNode.children, option)}
        </Box>
    )
}

export function wrapTDTag(
    domNode: Element,
    option: HTMLReactParserOptions,
    theme: ExtendedTheme
) {
    const tableDataProps = attributesToProps(domNode.attribs)

    return (
        <Box
            component={domNode.tagName as ElementType}
            sx={{
                p: theme.spacing(0.8),
                border: `0.1rem double ${theme.palette.grey[300]}`,
                minWidth: theme.spacing(4)
            }}
            {...tableDataProps}
        >
            {domToReact(domNode.children, option)}
        </Box>
    )
}

export function wrapFigcaptionTag(
    domNode: Element,
    option: HTMLReactParserOptions,
    theme: ExtendedTheme
) {
    const figcaptionProps = attributesToProps(domNode.attribs)

    return (
        <Box
            component={domNode.tagName as ElementType}
            sx={{
                captionSide: 'top',
                wordBreak: 'break-word',
                textAlign: 'center',
                backgroundColor: theme.palette.grey[100],
                padding: theme.spacing(0.9),
                fontSize: theme.spacing(1.5)
            }}
            {...figcaptionProps}
        >
            {domToReact(domNode.children, option)}
        </Box>
    )
}

export function wrapH1Tag(
    domNode: Element,
    option: HTMLReactParserOptions,
    theme: ExtendedTheme
) {
    const headingProps = attributesToProps(domNode.attribs)

    return (
        <Typography
            component={domNode.tagName as ElementType}
            variant="h2"
            {...headingProps}
        >
            {domToReact(domNode.children, option)}
        </Typography>
    )
}

export function wrapH2Tag(
    domNode: Element,
    option: HTMLReactParserOptions,
    theme: ExtendedTheme
) {
    const headingProps = attributesToProps(domNode.attribs)

    return (
        <Typography
            component={domNode.tagName as ElementType}
            variant="h3"
            {...headingProps}
        >
            {domToReact(domNode.children, option)}
        </Typography>
    )
}
export function wrapH3Tag(
    domNode: Element,
    option: HTMLReactParserOptions,
    theme: ExtendedTheme
) {
    const headingProps = attributesToProps(domNode.attribs)

    return (
        <Typography
            component={domNode.tagName as ElementType}
            variant="h4"
            {...headingProps}
        >
            {domToReact(domNode.children, option)}
        </Typography>
    )
}
export function wrapH4Tag(
    domNode: Element,
    option: HTMLReactParserOptions,
    theme: ExtendedTheme
) {
    const headingProps = attributesToProps(domNode.attribs)

    return (
        <Typography
            component={domNode.tagName as ElementType}
            variant="h5"
            {...headingProps}
        >
            {domToReact(domNode.children, option)}
        </Typography>
    )
}
export function wrapH5Tag(
    domNode: Element,
    option: HTMLReactParserOptions,
    theme: ExtendedTheme
) {
    const headingProps = attributesToProps(domNode.attribs)

    return (
        <Typography
            component={domNode.tagName as ElementType}
            variant="h6"
            {...headingProps}
        >
            {domToReact(domNode.children, option)}
        </Typography>
    )
}
export function wrapH6Tag(
    domNode: Element,
    option: HTMLReactParserOptions,
    theme: ExtendedTheme
) {
    const headingProps = attributesToProps(domNode.attribs)

    return (
        <Typography
            component={domNode.tagName as ElementType}
            variant="h6"
            {...headingProps}
        >
            {domToReact(domNode.children, option)}
        </Typography>
    )
}

export function wrapFigureTag(
    domNode: Element,
    option: HTMLReactParserOptions,
    theme: ExtendedTheme
) {
    const figureProps = attributesToProps(domNode.attribs)

    const isImageContainer = domNode.attribs.class?.includes('image')

    return (
        <Box
            component={domNode.tagName as ElementType}
            sx={{
                textAlign: 'center',
                m: isImageContainer ? '0 auto' : undefined
            }}
            {...figureProps}
        >
            {domToReact(domNode.children, option)}
        </Box>
    )
}

export function wrapImgTag(
    domNode: Element,
    option: HTMLReactParserOptions,
    theme: ExtendedTheme
) {
    const imgProps = attributesToProps(domNode.attribs)

    return (
        <Box
            component="img"
            sx={{
                maxWidth: '100%',
                height: 'auto'
            }}
            {...imgProps}
        />
    )
}

export function wrapEmbeddedThriveVideo(
    domNode: Element,
    option: HTMLReactParserOptions,
    theme: ExtendedTheme
) {
    const oembedProps = attributesToProps(domNode.attribs)

    const thriveVideoId = getThriveVideoIdFromEmbeddedTag(domNode)

    if (thriveVideoId) {
        // inject iframe with link to stream from video.thriveglobal.com instead of rendering oembed tag
        return (
            <div
                className="thrive-video-container"
                style={{
                    position: 'relative',
                    height: 0,
                    paddingBottom: '56.2493%'
                }}
            >
                <iframe
                    title="Thrive Video"
                    className="thrive-video-iframe"
                    src={`${THRIVE_VIDEO_DOMAIN}/${thriveVideoId}`}
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0
                    }}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                />
            </div>
        )
    }

    return (
        // <oembed /> is a tag returned from CMS for embedded videos
        // https://ckeditor.com/docs/ckeditor5/latest/features/media-embed.html#semantic-data-output-default
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <oembed {...oembedProps}>{domToReact(domNode.children, option)}</oembed>
    )
}
