import {
    SuccessStoryFeedback,
    SuccessStoryFeedbackType,
    useGetSuccessStoryFeedbackLazyQuery
} from '../../graphql/generated/autogenerated'
import { ApolloError } from '@apollo/client'
import { useCallback } from 'react'

export type UseSuccessStoryFeedbackFormContentResult = {
    successStoryFeedbackFormContent: SuccessStoryFeedback | null
    isLoading: boolean
    getSuccessStoryFeedbackFormContent: (type: SuccessStoryFeedbackType) => void
    notFound: boolean
    isPristine: boolean
    error: Error | ApolloError | null
}

export function useSuccessStoryFeedbackFormContent(): UseSuccessStoryFeedbackFormContentResult {
    const [get, { data, loading, called, error = null }] =
        useGetSuccessStoryFeedbackLazyQuery({ errorPolicy: 'all' })

    const getSuccessStoryFeedbackFormContent = useCallback(
        (type: SuccessStoryFeedbackType) => {
            get({
                variables: {
                    type: type
                }
            })
        },
        [get]
    )

    const successStoryFeedbackFormContent =
        (data?.content?.successStory
            ?.getSuccessStoryFeedback as SuccessStoryFeedback) ?? null

    return {
        successStoryFeedbackFormContent,
        isLoading: loading,
        error,
        getSuccessStoryFeedbackFormContent,
        notFound:
            called && !loading && successStoryFeedbackFormContent === null,
        isPristine: !called
    }
}
