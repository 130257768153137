import { useMemo } from 'react'
import {
    GetMostViewedContentQuery,
    LearnContentItem,
    useGetMostViewedContentQuery
} from '../../graphql/generated/autogenerated'
import { ApolloError } from '@apollo/client'
import {
    TransformedContentItem,
    useTransformBrowseByTopicItem
} from '../browse'

export type UseMostViewedContentResult = {
    isLoading: boolean
    error: Error | ApolloError | null
    mostViewedContent: TransformedContentItem[]
}

export type UseMostViewedContentOptions = {
    limit?: number
}

export const defaultOptions: UseMostViewedContentOptions = {
    limit: 10
}

const dataSelector = (data: GetMostViewedContentQuery | undefined) => {
    return (
        (data?.content?.thrive?.getMostViewedContent as LearnContentItem[]) ??
        []
    )
}

export function useMostViewedContent(
    options = defaultOptions
): UseMostViewedContentResult {
    const { limit } = options

    const {
        data,
        error = null,
        loading
    } = useGetMostViewedContentQuery({
        variables: {
            limit
        }
    })

    const mostViewedContent = dataSelector(data)

    const transformMostViewedContent = useTransformBrowseByTopicItem()

    const transformedMostViewedContent = useMemo(() => {
        return mostViewedContent.map(transformMostViewedContent)
    }, [mostViewedContent, transformMostViewedContent])

    return {
        isLoading: loading,
        error,
        mostViewedContent: transformedMostViewedContent
    }
}
