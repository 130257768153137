import { useEffect, useState } from 'react'

/**
 * Returns the width of the image based on ratio of the height provided or the default width
 * @param src The image url
 * @param height The height the width should be calculated against
 * @param defaultWidth The fallback width
 * @returns number
 */
export default function useGetImageWidth(
    src: string,
    height: number,
    defaultWidth: number
) {
    const [width, setWidth] = useState(defaultWidth)

    useEffect(() => {
        const img = new Image()

        img.onload = () => {
            try {
                setWidth((height * img.width) / img.height)
            } catch (e) {
                setWidth(defaultWidth)
            }
        }

        img.src = src
    }, [src, height, defaultWidth])

    return width
}
