/* eslint-disable @typescript-eslint/ban-ts-comment */
import { lazy, Suspense } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { ProtectedRoute } from '@thriveglobal/thrive-web-auth-core'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { PageProvider } from '../utils'
import { ROUTES } from './routes'

const OverviewPage = lazy(() => import('../pages/overview/OverviewPage'))
const CoursesPage = lazy(() => import('../pages/courses/CoursesPage'))
const CoursePage = lazy(() => import('../pages/course/CoursePage'))
const SuccessStoriesPage = lazy(
    () => import('../pages/activity/success-stories/SuccessStoriesPage')
)
const SuccessStoryPage = lazy(
    () => import('../pages/activity/success-stories/SuccessStoryPage')
)
const BookmarksPage = lazy(
    () => import('../pages/activity/bookmarks/BookmarksPage')
)
const ActivityPage = lazy(() => import('../pages/activity/ActivityPage'))

const ArticlesPage = lazy(() => import('../pages/articles/ArticlesPage'))
const ArticlePage = lazy(() => import('../pages/article/ArticlePage'))
const CompanyResourcesPage = lazy(
    () => import('../pages/company-resources/CompanyResourcesPage')
)
const PodcastPage = lazy(() => import('../pages/podcast/PodcastPage'))
const PodcastsPage = lazy(() => import('../pages/podcasts/PodcastsPage'))
const BookPage = lazy(() => import('../pages/book/BookPage'))
const BooksPage = lazy(() => import('../pages/books/BooksPage'))
const WebinarRegistrationPage = lazy(
    () => import('../pages/webinar/webinar-registration/RegisterForWebinarPage')
)
const WebinarPage = lazy(() => import('../pages/webinar/WebinarPage'))
const WebinarsPage = lazy(() => import('../pages/webinars/WebinarsPage'))
const BrowsePage = lazy(
    () => import('../pages/browse-by-topic/BrowseByTopicPage')
)
const AllTopicsPage = lazy(() => import('../pages/all-topics/AllTopicsPage'))

const Router: React.FC = () => {
    const theme = useTheme()

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <PageProvider>
                    <Suspense fallback={<></>}>
                        <Switch>
                            <ProtectedRoute
                                path={ROUTES.COURSE}
                                component={CoursePage}
                            />
                            <ProtectedRoute
                                path={ROUTES.COURSES}
                                component={CoursesPage}
                            />
                            <ProtectedRoute
                                path={ROUTES.ARTICLE}
                                component={ArticlePage}
                            />
                            <ProtectedRoute
                                path={ROUTES.ARTICLES}
                                component={ArticlesPage}
                            />
                            <ProtectedRoute
                                path={ROUTES.BROWSE_TOPIC}
                                component={BrowsePage}
                            />
                            <ProtectedRoute
                                path={ROUTES.BROWSE}
                                component={AllTopicsPage}
                            />
                            <ProtectedRoute
                                path={ROUTES.COMPANY_RESOURCES}
                                component={CompanyResourcesPage}
                            />
                            <ProtectedRoute
                                path={ROUTES.PODCAST}
                                component={PodcastPage}
                            />
                            <ProtectedRoute
                                path={ROUTES.PODCASTS}
                                component={PodcastsPage}
                            />
                            <ProtectedRoute
                                path={ROUTES.BOOK}
                                component={BookPage}
                            />
                            <ProtectedRoute
                                path={ROUTES.BOOKS}
                                component={BooksPage}
                            />
                            <ProtectedRoute
                                path={ROUTES.ACTIVITY}
                                component={ActivityPage}
                            />
                            <ProtectedRoute
                                path={ROUTES.BOOKMARKS}
                                component={BookmarksPage}
                            />
                            <ProtectedRoute
                                path={ROUTES.SUCCESS_STORY}
                                component={SuccessStoryPage}
                            />
                            <ProtectedRoute
                                path={ROUTES.SUCCESS_STORIES}
                                component={SuccessStoriesPage}
                            />
                            <ProtectedRoute
                                path={ROUTES.WEBINAR_REGISTRATION}
                                component={WebinarRegistrationPage}
                            />
                            <ProtectedRoute
                                path={ROUTES.WEBINAR}
                                component={WebinarPage}
                            />
                            <ProtectedRoute
                                path={ROUTES.WEBINARS}
                                component={WebinarsPage}
                            />
                            <ProtectedRoute
                                path={ROUTES.OVERVIEW}
                                component={OverviewPage}
                            />
                        </Switch>
                    </Suspense>
                </PageProvider>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default Router
