export enum ArticleType {
    Text = 'TextArticle',
    Video = 'VideoArticle'
}

export enum ArticleFilter {
    RoleModel = 'rolemodel',
    Recipe = 'recipe',
    WatchMeThrive = 'watchmethrive'
}
