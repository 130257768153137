import { useCallback, useEffect, useMemo } from 'react'
import { useGetFeatureConfig } from '@thriveglobal/thrive-web-core'
import { ApolloError } from '@apollo/client'
import {
    ContentFeedback,
    CourseV2,
    LearnContentStatusProgressType,
    LearnContentStatusType,
    useGetCourseLazyQuery,
    useGetCoursesQuery,
    useGiveFeedbackOnCourseMutation,
    useUpdateModuleStatusMutation
} from '../../graphql/generated/autogenerated'
import { TransformedCourse, TransformedCourses } from './types'
import { useTransformCourse, useTransformCourses } from './transformers'
import { FeatureFlags } from '../../constants'

export * from './transformers'
export * from './types'
interface UseCourse {
    getCourse: (id: string) => void
    course: TransformedCourse | null
    isLoading: boolean
    isPristine: boolean
    notFound: boolean
    error: Error | ApolloError | null
}

export const useCourse = (): UseCourse => {
    const transformCourse = useTransformCourse()

    const [
        get,
        {
            loading,
            called,
            error: requestError = null,
            data = { learnV2: { getCourse: null } }
        }
    ] = useGetCourseLazyQuery({ errorPolicy: 'all' })

    const getCourse = useCallback(
        (id: any) => {
            get({ variables: { id } })
        },
        [get]
    )

    const course = useMemo(
        () =>
            data.learnV2?.getCourse
                ? transformCourse(data.learnV2?.getCourse as CourseV2)
                : null,
        [data.learnV2?.getCourse]
    )

    return {
        getCourse,
        notFound: called && !loading && course === null,
        course,
        isLoading: loading,
        isPristine: !called,
        error: requestError
    }
}

type UseFeaturedCourse = Pick<UseCourse, 'course' | 'isLoading' | 'notFound'>

export const useFeaturedCourse = (): UseFeaturedCourse => {
    const featuredCourseId = useGetFeatureConfig<number | string>(
        FeatureFlags.LibraryFeaturedCourseId,
        false,
        true
    )

    const { getCourse, isLoading, course, isPristine, notFound } = useCourse()

    useEffect(() => {
        if (!isPristine || !featuredCourseId) return
        getCourse(String(featuredCourseId))
        // isPristine && getCourse(config('featured-course-id') as string)
    }, [isPristine, getCourse, featuredCourseId])

    return {
        notFound: notFound || !featuredCourseId,
        course,
        isLoading: isPristine || isLoading
    }
}

interface UseCourses {
    courses: TransformedCourses[]
    noCourses: boolean
    hasMore: boolean
    isFetchingInitial: boolean
    isFetchingMore: boolean
    refetch: () => void
    fetchMore: () => void
    error?: ApolloError
}

export const useCourses = ({
    pageSize
}: { pageSize?: number } = {}): UseCourses => {
    const transformCourses = useTransformCourses()

    pageSize = pageSize || 10

    const {
        refetch: refetchCourses,
        loading,
        data,
        fetchMore: fetchMoreCourses,
        error
    } = useGetCoursesQuery({
        variables: { page: 1, pageSize },
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true
    })

    const hasMore = !!data?.learnV2?.getCourses?.hasMore
    const currentPage = data?.learnV2?.getCourses?.page || 1
    const nextPage = currentPage + 1

    const courses = useMemo(
        () =>
            ((data?.learnV2?.getCourses?.items || []) as CourseV2[])
                .filter((course) => !!course)
                .map((course) => transformCourses(course)),
        [data?.learnV2?.getCourses?.items]
    )

    const noCourses = !loading && courses.length === 0

    const fetchMore = useCallback(() => {
        fetchMoreCourses({ variables: { pageSize, page: nextPage } })
    }, [nextPage, pageSize, fetchMoreCourses])

    const refetch = useCallback(() => {
        refetchCourses({ pageSize, page: 1 })
    }, [pageSize, refetchCourses])

    return {
        courses,
        fetchMore,
        refetch,
        isFetchingInitial: loading && courses.length === 0,
        isFetchingMore: loading && courses.length > 0,
        hasMore,
        noCourses,
        error
    }
}

export const useUpdateModuleStatus = () => {
    const [updateModuleStatus, { data, loading }] =
        useUpdateModuleStatusMutation()

    const update = useCallback(
        (
            args: {
                contentId: string
                progressInSeconds: number
                totalInSeconds: number
                isComplete: boolean
            },
            refetchQueries: string[] = []
        ) => {
            const { contentId, progressInSeconds, totalInSeconds, isComplete } =
                args

            const progressType =
                LearnContentStatusProgressType[
                    isComplete ? 'Finished' : 'InProgress'
                ]

            updateModuleStatus({
                variables: {
                    input: {
                        contentId,
                        progressType,
                        progressInSeconds: Math.round(progressInSeconds),
                        totalInSeconds: Math.round(totalInSeconds)
                    }
                },
                refetchQueries
            })
        },
        [updateModuleStatus]
    )

    const moduleStatus = useMemo(() => {
        if (data?.learnV2?.updateModuleStatus)
            return {
                ...data.learnV2.updateModuleStatus
            }

        return null
    }, [data])

    return {
        update,
        moduleStatus,
        isUpdating: loading
    }
}

export const useSubmitCourseFeedback = () => {
    const [giveFeedbackOnCourse, { data, loading }] =
        useGiveFeedbackOnCourseMutation()

    const submitFeedback = useCallback(
        (
            args: {
                contentId: string
                contentType: LearnContentStatusType
                feedbackAnswer: ContentFeedback
                feedbackQuestion: string
                openQuestion?: string
                openAnswer?: string
            },
            refetchQueries: string[] = []
        ) => {
            const {
                contentId,
                contentType,
                feedbackAnswer,
                feedbackQuestion,
                openQuestion,
                openAnswer
            } = args

            giveFeedbackOnCourse({
                variables: {
                    contentId,
                    contentType,
                    feedbackAnswer,
                    feedbackQuestion,
                    openQuestion,
                    openAnswer
                },
                refetchQueries
            })
        },
        [giveFeedbackOnCourse]
    )

    return {
        submitFeedback,
        isUpdating: loading
    }
}
