import { defineMessages, IntlShape } from 'react-intl'
import { convertSecondsToMinutes } from './convert-seconds-to-minutes'
import { LearnContentStatusSubtype } from '../graphql/generated/autogenerated'

const intlMessages = defineMessages({
    text: {
        defaultMessage: '{durationInMinutes} min read',
        description:
            'The amount of time in minutes (abbreviated) estimated to read an article'
    },
    video: {
        defaultMessage: '{durationInMinutes} min watch',
        description:
            'The duration of time in minutes (abbreviated) to watch a video article'
    },
    audio: {
        defaultMessage: '{durationInMinutes} min listen',
        description:
            'The duration of time in minutes (abbreviated) to listen to a podcast'
    }
})

export function durationTextString(
    durationInSeconds: number,
    intl: IntlShape,
    subType: LearnContentStatusSubtype
) {
    const durationInMinutes =
        durationInSeconds > 60 ? convertSecondsToMinutes(durationInSeconds) : 1
    return `${
        {
            [LearnContentStatusSubtype.Audio]: intl.formatMessage(
                intlMessages.audio,
                { durationInMinutes }
            ),
            [LearnContentStatusSubtype.Text]: intl.formatMessage(
                intlMessages.text,
                { durationInMinutes }
            ),
            [LearnContentStatusSubtype.Video]: intl.formatMessage(
                intlMessages.video,
                { durationInMinutes }
            )
        }[subType]
    }`
}
