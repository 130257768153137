import { KeyboardEvent } from 'react'

export const ifEnter = (callback?: (e: KeyboardEvent) => void) => {
    return (e: KeyboardEvent) => {
        const { key } = e
        if (key.toLowerCase() === 'enter' && callback) callback(e)
    }
}

// export const ifDelete = (callback?: (e: KeyboardEvent) => void) => {
//     return (e: KeyboardEvent) => {
//         const { key } = e
//         if (key.toLowerCase() === 'delete' && callback) callback(e)
//     }
// }

// export const ifArrowDown = (callback?: (e: KeyboardEvent) => void) => {
//     return (e: KeyboardEvent) => {
//         const { key } = e
//         if (key.toLowerCase() === 'arrowdown' && callback) callback(e)
//     }
// }

// export const ifArrowUp = (callback?: (e: KeyboardEvent) => void) => {
//     return (e: KeyboardEvent) => {
//         const { key } = e
//         if (key.toLowerCase() === 'arrowup' && callback) callback(e)
//     }
// }

// export const ifEscape = (callback?: (e: KeyboardEvent) => void) => {
//     return (e: KeyboardEvent) => {
//         const { key } = e
//         if (key.toLowerCase() === 'escape' && callback) callback(e)
//     }
// }

// export const ifTab = (callback?: (e: KeyboardEvent) => void) => {
//     return (e: KeyboardEvent) => {
//         const { key } = e
//         if (key.toLowerCase() === 'tab' && callback) callback(e)
//     }
// }

// export const ifIs = (
//     character: string,
//     callback?: (e: KeyboardEvent) => void
// ) => {
//     return (e: KeyboardEvent) => {
//         const { key } = e
//         if (key === character && callback) callback(e)
//     }
// }

// export const ifIsOneOf = (
//     characters: string[],
//     callback?: (e: KeyboardEvent) => void
// ) => {
//     return (e: KeyboardEvent) => {
//         const { key } = e
//         if (characters.includes(key) && callback) callback(e)
//     }
// }

// export const prevent = (callback?: (e: Event) => void) => {
//     return (e: Event) => {
//         e.preventDefault()
//         callback && callback(e)
//     }
// }
