import { useMemo, useCallback, useState } from 'react'
import {
    useGetContentByTopicLazyQuery,
    LearnContentItem,
    Topic,
    GetContentByTopicQuery
} from '../../graphql/generated/autogenerated'
import { useTransformBrowseByTopicItem } from './transformers'
import { TransformedContentItem } from './types'
import { ApolloQueryResult, QueryResult } from '@apollo/client'

interface UseBrowse {
    getTopicResults: (
        topicId: string,
        topicType: string,
        page?: number,
        pageSize?: number
    ) => void
    topicTitle: Topic
    pageTitle: string
    topicResults: TransformedContentItem[]
    isPristine: boolean
    isLoading: boolean
    topicResultsNotFound: boolean
    hasResults: boolean
    isFetchingInitial: boolean
    isFetchingMore: boolean
    hasMoreResults: boolean
    fetchMoreResults: () => void
}

export * from './transformers'
export * from './types'

export const useBrowse = ({
    pageSize
}: { pageSize?: number } = {}): UseBrowse => {
    const transformBrowseContent = useTransformBrowseByTopicItem()
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [topicResults, setTopicResults] = useState<TransformedContentItem[]>(
        []
    )

    pageSize = pageSize || 9

    const [
        get,
        {
            loading,
            called,
            data = { content: { thrive: { getContentByTopic: null } } },
            fetchMore
        }
    ] = useGetContentByTopicLazyQuery({
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true
    })

    const handleResponse = (data: GetContentByTopicQuery | undefined) => {
        setPage(data?.content.thrive.getContentByTopic.itemPage.page || 1)
        setHasMore(
            data?.content.thrive.getContentByTopic.itemPage.hasMore || false
        )
        setTopicResults((prevResults) => [
            ...prevResults,
            ...(data?.content.thrive.getContentByTopic?.itemPage?.items.map(
                transformBrowseContent
            ) || [])
        ])
    }

    const getTopicResults = useCallback(
        (type: string, topicId: string) => {
            const isJourney = type === 'Journey'

            get({
                variables: {
                    pageSize,
                    topic: {
                        journeyUUID: isJourney ? topicId : null,
                        pulseDimensionType: !isJourney ? topicId : null
                    }
                }
            }).then((res) => handleResponse(res.data))
        },
        [get]
    )

    const topicTitle = useMemo(
        () => (data.content?.thrive?.getContentByTopic?.topic || {}) as Topic,
        [data.content?.thrive?.getContentByTopic?.topic]
    )

    const hasMoreResults =
        !!data.content?.thrive?.getContentByTopic?.itemPage?.hasMore
    const currentPage =
        data.content?.thrive?.getContentByTopic?.itemPage.page || 1
    const nextPage = currentPage + 1

    const fetchMoreResults = useCallback(() => {
        if (!fetchMore || !hasMore) return

        fetchMore({ variables: { pageSize, page: page + 1 } }).then((res) =>
            handleResponse(res.data)
        )
    }, [
        fetchMore,
        hasMore,
        pageSize,
        page,
        topicResults,
        transformBrowseContent
    ])

    return {
        getTopicResults,
        topicTitle,
        pageTitle:
            topicTitle.journey?.shortName ||
            topicTitle.pulseDimension?.name ||
            '',
        topicResults,
        fetchMoreResults,
        isFetchingInitial: loading && topicResults.length === 0,
        isFetchingMore: loading && topicResults.length > 0,
        isLoading: loading,
        isPristine: !called,
        topicResultsNotFound: called && !loading && topicResults === null,
        hasResults: topicResults.length > 0,
        hasMoreResults
    }
}
