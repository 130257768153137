import { useMemo, useCallback } from 'react'

export * from './transformers'
export * from './types'

import { useMarkCourseResourceAsCompletedMutation } from '../../graphql/generated/autogenerated'

export const useMarkResourceAsComplete = () => {
    const [markResourceAsCompletedMutation, { data, loading }] =
        useMarkCourseResourceAsCompletedMutation()

    const markAsComplete = useCallback(
        (
            args: {
                resourceId: string
            },
            refetchQueries: string[] = []
        ) => {
            const { resourceId } = args

            markResourceAsCompletedMutation({
                variables: {
                    resourceId
                },
                refetchQueries
            })
        },
        [markResourceAsCompletedMutation]
    )

    const resourceStatus = useMemo(() => {
        if (data?.learnV2?.markCourseResourceAsCompleted)
            return {
                ...data.learnV2.markCourseResourceAsCompleted
            }

        return null
    }, [data])

    return {
        markAsComplete,
        resourceStatus,
        isUpdating: loading
    }
}
