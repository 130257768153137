import { useCallback } from 'react'
import { CourseResourceType } from '../../graphql/generated/autogenerated'
import { TransformedResource, ResourceLike } from './types'
import { useIntl, defineMessages } from 'react-intl'
import { IconName } from '@fortawesome/fontawesome-common-types'

const intlMessages = defineMessages({
    startQuiz: { defaultMessage: 'Start Quiz', description: 'To begin a quiz' },
    startActivity: {
        defaultMessage: 'Start Activity',
        description: 'To begin an activity'
    },
    worksheet: { defaultMessage: 'Worksheet', description: ' Worksheet' }
})
const icons: { [key in CourseResourceType]: IconName } = {
    [CourseResourceType.Survey]: 'location-question',
    [CourseResourceType.Pdf]: 'memo-pad',
    [CourseResourceType.Link]: 'file-lines'
}

export const useTransformResource = () => {
    const intl = useIntl()

    const names: { [key in CourseResourceType]: string } = {
        [CourseResourceType.Survey]: intl.formatMessage({
            defaultMessage: 'Quiz',
            description: 'A questionnaire'
        }),
        [CourseResourceType.Pdf]: intl.formatMessage(intlMessages.worksheet),
        [CourseResourceType.Link]: intl.formatMessage(intlMessages.worksheet)
    }

    const actionNames: { [key in CourseResourceType]: string } = {
        [CourseResourceType.Survey]: intl.formatMessage(intlMessages.startQuiz),
        [CourseResourceType.Pdf]: intl.formatMessage(
            intlMessages.startActivity
        ),
        [CourseResourceType.Link]: intl.formatMessage(
            intlMessages.startActivity
        )
    }

    const transform = useCallback(
        (resource: ResourceLike): TransformedResource => {
            return {
                id: resource.id,
                title: resource.title,
                summary: resource.summary ?? null,
                source: resource.source,
                type: resource.resourceType,
                icon: icons[resource.resourceType],
                name: names[resource.resourceType],
                isComplete: resource.hasCompleted,
                actionName: actionNames[resource.resourceType]
            }
        },
        []
    )

    return transform
}
