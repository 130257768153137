export const PLACEHOLDER_IMAGE = `${process.env.CDN_ASSETS}/journeys/journey_onboarding_placeholder.png`
export const PLACEHOLDER_ICON_IMAGE = `${process.env.CDN_ASSETS}/company_logos/Thrive-FullLogo-Flat-OnDark-LowRes.png`
export const PLACEHOLDER_FEATURED_CARD_IMAGE = `${process.env.CDN_ASSETS}/library/decorative_images/microsteps_library_overview.jpg`

export const MEDIA_SOFT_COMPLETE_PERCENTAGE = 80
export const ARTICLES_LOAD_MORE_SIZE = 18
export const WEBINARS_LOAD_MORE_SIZE = 24
export const COURSES_LOAD_MORE_SIZE = 9
export const PODCASTS_LOAD_MORE_SIZE = 9
export const BROWSE_BY_TOPIC_LOAD_MORE_SIZE = 9
export const BOOKMARKS_LOAD_MORE_SIZE = 9
export const MY_SUCCESS_STORIES_LOAD_MORE_SIZE = 9
export const BOOKS_LOAD_MORE_SIZE = 3
export const RESETS_LOAD_MORE_SIZE = 24
export const PERSONAL_MICROSTEPS_LOAD_MORE_SIZE = 3
export const MICROSTEPS_LOAD_MORE_SIZE = 9

export const THRIVE_INTERNAL_URLS = ['thriveglobal.com', 'www.thriveglobal.com']
export const SOCIAL_URLS = [
    'www.instagram.com',
    'www.facebook.com',
    'www.twitter.com',
    'www.youtube.com',
    'instagram.com',
    'facebook.com',
    'twitter.com',
    'youtube.com'
]
export const THRIVE_VIDEO_DOMAIN = 'https://video.thriveglobal.com'
export const CLOSED_CAPTION_LANGUAGE_CODES = ['fr-CA', 'zh-Hans']
export const CERTIFICATE_ORGANISATION_ID = '15080091'
export const CERTIFICATE_URL =
    'https%3A%2F%2Fthriveglobal.com%2Fproducts%2Flearn-programs'
export const HIDE_RESOURCES_THAT_INCLUDE = ['qualtrics']
export const RESETS_APP =
    'single-spa-application:@thriveglobal/thrive-web-reset'
export const SEARCH_APP =
    'single-spa-application:@thriveglobal/thrive-web-search'
export const LEARN_APP = 'single-spa-application:@thriveglobal/thrive-web-learn'

export const GOOGLE_CALENDAR_URL =
    'https://calendar.google.com/calendar/u/0/r/eventedit'
export const OFFICE365_CALENDAR_URL =
    'https://outlook.office365.com/calendar/0/deeplink/compose'
export const OUTLOOK_CALENDAR_URL =
    'https://outlook.live.com/calendar/0/deeplink/compose'

export const RESET_LOCATION =
    'https://app.thriveglobal.com/reset?utm_source=calendar'

export const TODAYS_RESET =
    'https://app.thriveglobal.com/reset/thrive/today?utm_source=calendar'

export const PERSONAL_RESET =
    'https://app.thriveglobal.com/reset/new?utm_source=calendar'

export const MICROSTEP_CHECKIN_DATE_FORMAT = 'YYYY-MM-DD'
