import {
    ArticleContentType,
    LearnContentStatusType
} from '../graphql/generated/autogenerated'
import { IconName } from '@fortawesome/fontawesome-common-types'

const contentStatusIconMap: Record<LearnContentStatusType, IconName> = {
    [LearnContentStatusType.AcuteCareResource]: 'pot-food',
    [LearnContentStatusType.AudioBookChapterPart]: 'book',
    [LearnContentStatusType.Course]: 'graduation-cap',
    [LearnContentStatusType.CourseResource]: 'graduation-cap',
    [LearnContentStatusType.JourneyDailyStep]: 'pot-food',
    [LearnContentStatusType.Lesson]: 'graduation-cap',
    [LearnContentStatusType.Microstep]: 'circle-check',
    [LearnContentStatusType.Module]: 'graduation-cap',
    [LearnContentStatusType.Podcast]: 'headphones',
    [LearnContentStatusType.PodcastEpisode]: 'headphones',
    [LearnContentStatusType.Reset]: 'waveform-lines',
    [LearnContentStatusType.WatchMeThrive]: 'video',
    [LearnContentStatusType.Webinar]: 'podium',
    [LearnContentStatusType.RoleModel]: 'trophy',
    [LearnContentStatusType.Article]: 'newspaper',
    [LearnContentStatusType.Recipe]: 'pot-food'
}

export default function getContentTypeIcon(
    contentStatusType?: LearnContentStatusType,
    articleContentType?: ArticleContentType
): IconName {
    if (
        (contentStatusType === LearnContentStatusType.RoleModel ||
            contentStatusType === LearnContentStatusType.Article) &&
        articleContentType === ArticleContentType.Video
    ) {
        return 'video'
    }

    if (contentStatusType) {
        return contentStatusIconMap[contentStatusType] || 'newspaper'
    }

    return 'newspaper'
}
