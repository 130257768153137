import {
    ComponentType,
    createContext,
    memo,
    ReactNode,
    useCallback,
    useContext,
    useMemo,
    useState
} from 'react'
import { UploadError } from '../../../../utils/useUserImageUpload'

export type SuccessStoryFormErrorContextValue = {
    error: UploadError | null
    setError: (error: UploadError | null) => void
    clearError: () => void
}

const defaultContextValue: SuccessStoryFormErrorContextValue = {
    error: null,
    setError: () => {},
    clearError: () => {}
}

const SuccessStoryFormErrorContext = createContext(defaultContextValue)

export function SuccessStoryFormErrorProvider({
    children
}: {
    children: ReactNode
}) {
    const [error, setError] = useState<UploadError | null>(null)

    const clearError = useCallback(() => {
        setError(null)
    }, [])

    const providerValue = useMemo<SuccessStoryFormErrorContextValue>(() => {
        return {
            clearError,
            error,
            setError
        }
    }, [clearError, error])

    return (
        <SuccessStoryFormErrorContext.Provider value={providerValue}>
            {children}
        </SuccessStoryFormErrorContext.Provider>
    )
}

export function withSuccessStoryFormErrorProvider<TProps extends {}>(
    Component: ComponentType<TProps>
) {
    return memo(function SuccessStoryFormErrorProviderWrapper(props: TProps) {
        return (
            <SuccessStoryFormErrorProvider>
                <Component {...props} />
            </SuccessStoryFormErrorProvider>
        )
    })
}

export function useSuccessStoryFormErrors() {
    const { error, setError, clearError } = useContext(
        SuccessStoryFormErrorContext
    )

    return useMemo(() => {
        return {
            error,
            setError,
            clearError
        }
    }, [clearError, error, setError])
}
