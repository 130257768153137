import { LearnContentStatusType } from '../graphql/generated/autogenerated'

export default (contentType: LearnContentStatusType) => {
    switch (contentType) {
        case LearnContentStatusType.Article:
            return '?filter=article'
        case LearnContentStatusType.RoleModel:
            return '?filter=rolemodel'
        case LearnContentStatusType.Recipe:
            return '?filter=recipe'
        case LearnContentStatusType.WatchMeThrive:
            return '?filter=watchmethrive'
        default:
            return ''
    }
}
