import {
    MySuccessStory,
    useGetMySuccessStoriesQuery
} from '../../graphql/generated/autogenerated'
import { useCallback, useMemo } from 'react'

export type UseMySuccessStories = (params: { pageSize?: number }) => {
    mySuccessStories: MySuccessStory[]
    noMySuccessStories: boolean
    hasMore: boolean
    isLoading: boolean
    isFetchingMore: boolean
    fetchMore: () => void
    refetch: () => void
}

export const useMySuccessStories: UseMySuccessStories = ({
    pageSize: _pageSize
}) => {
    const pageSize = _pageSize || 10

    const {
        refetch: refetchMySuccessStories,
        loading,
        data,
        fetchMore: fetchMoreMySuccessStories,
        called
    } = useGetMySuccessStoriesQuery({
        variables: { page: 1, pageSize },
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true
    })

    const hasMore = !!data?.content?.successStory?.getMySuccessStories?.hasMore
    const currentPage =
        data?.content?.successStory?.getMySuccessStories?.page || 1
    const nextPage = currentPage + 1

    const mySuccessStories = useMemo(() => {
        return (
            (data?.content?.successStory?.getMySuccessStories
                ?.items as MySuccessStory[]) ?? []
        )
    }, [data?.content?.successStory?.getMySuccessStories?.items])

    const noMySuccessStories =
        called && !loading && mySuccessStories.length === 0

    const fetchMore = useCallback(() => {
        fetchMoreMySuccessStories({ variables: { pageSize, page: nextPage } })
    }, [fetchMoreMySuccessStories, nextPage, pageSize])

    const refetch = useCallback(() => {
        refetchMySuccessStories({ pageSize, page: 1 })
    }, [refetchMySuccessStories, pageSize])

    return {
        mySuccessStories,
        noMySuccessStories,
        hasMore,
        isLoading: loading && mySuccessStories.length === 0,
        isFetchingMore: loading && mySuccessStories.length > 0,
        refetch,
        fetchMore
    }
}
