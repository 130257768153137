import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../routes'

type Options = { replaceHistory?: boolean; referrerScreen?: string }

export const useGoToPage = () => {
    const history = useHistory()

    const go = useCallback(
        (url: string, options: Options = {}) => {
            const state = {
                referrerScreen: options.referrerScreen
            }

            if (options.replaceHistory) {
                history.replace(url, state)

                return
            }

            history.push(url, state)
        },
        [history]
    )

    const goToHomePage = useCallback(
        (options: Options = {}) => {
            go(ROUTES.OVERVIEW, options)
        },
        [go]
    )

    const goToWebinarsPage = useCallback(
        (options: Options = {}) => {
            go(ROUTES.WEBINARS, options)
        },
        [go]
    )

    const goToWebinarPage = useCallback(
        (webinarId: string, options: Options = {}) => {
            go(`${ROUTES.WEBINARS}/${webinarId}`, options)
        },
        [go]
    )

    const goToArticlesPage = useCallback(
        (filter?: string, options: Options = {}) => {
            go(
                `${ROUTES.ARTICLES}${filter ? `?filter=${filter}` : ''}`,
                options
            )
        },
        [go]
    )

    const goToArticlePage = useCallback(
        (articleId: string, filter?: string, options: Options = {}) => {
            go(
                `${ROUTES.ARTICLES}/${articleId}${
                    filter ? `?filter=${filter.toLowerCase()}` : ''
                }`,
                options
            )
        },
        [go]
    )

    const goToBooksPage = useCallback(
        (options: Options = {}) => {
            go(ROUTES.BOOKS, options)
        },
        [go]
    )

    const goToSuccessStoriesPage = useCallback(
        (options: Options = {}) => {
            go(ROUTES.SUCCESS_STORIES, options)
        },
        [go]
    )

    const goToLink = useCallback(
        (url: string) => {
            try {
                const link = new URL(url)
                if (window.location.hostname === link.hostname) {
                    history.push(link.pathname)
                    window.scrollTo(0, 0)
                } else {
                    window.open(url)
                }
            } catch (error) {
                if (error instanceof TypeError) {
                    return url
                }
            }
        },
        [history]
    )

    return {
        goToHomePage,
        goToWebinarPage,
        goToWebinarsPage,
        goToArticlePage,
        goToArticlesPage,
        goToBooksPage,
        goToSuccessStoriesPage,
        goToLink
    }
}
