export interface Options {
    element: Element | null
    root?: null | Element
    rootMargin?: string
    threshold?: number
}

export interface Unsubscribe {
    (): void
}

export interface Callback {
    (entry?: IntersectionObserverEntry): void
}

export const onEnter = (
    callback: Callback,
    {
        element,
        root = null,
        rootMargin = '0px 0px 0px 0px',
        threshold = 0
    }: Options
): Unsubscribe => {
    const observer = new IntersectionObserver(
        ([entry]) => {
            if (entry.isIntersecting) callback(entry)
        },
        { rootMargin, threshold, root }
    )

    element && observer.observe(element)

    return () => element && observer.unobserve(element)
}
