import { useIntl, defineMessages } from 'react-intl'
import { useCallback } from 'react'
import {
    TransformedAudioBooks,
    TransformedBook,
    TransformedAudioBookEpisode
} from './types'
import {
    AudioBookChapter,
    AudioBookChapterPart,
    Book
} from '../../graphql/generated/autogenerated'
import { convertSecondsToMinutes } from '../../utils/convert-seconds-to-minutes'

import { MEDIA_SOFT_COMPLETE_PERCENTAGE } from '../../utils/constants'
import { usePageContext } from '../../utils'

const intlMessages = defineMessages({
    completed: {
        defaultMessage: 'Completed',
        description: `To have completed a task`
    },
    inProgress: {
        defaultMessage: 'In Progress',
        description: `To be in progress of a task`
    }
})

export const useTransformBook = () => {
    const intl = useIntl()
    const { isEnUsLang } = usePageContext()
    const transformAudioChapters = useTransformAudioBooks()

    const getResource = (resourceUrl: string) => {
        const lastThreeCharacters = resourceUrl.toLowerCase().endsWith('.pdf')
        if (lastThreeCharacters) return 'PDF'

        return ''
    }

    const transform = useCallback((book: Book): TransformedBook => {
        const audioChapters = (
            (book?.audioChapters || []) as AudioBookChapter[]
        ).map((chapters) => transformAudioChapters(chapters))

        const chapterCount = audioChapters.length
        const languageText = isEnUsLang ? 'English' : null
        const resourceType = book.resourceUrl
            ? getResource(book.resourceUrl)
            : ''

        const chapterCountText = intl.formatMessage(
            {
                defaultMessage: '{numberOfChapters} Chapters',
                description:
                    'The total number of chapters in the book - For example: 20 Chapters'
            },
            { numberOfChapters: chapterCount }
        )

        const totalDurationSeconds = audioChapters.reduce(
            (total, chapter) =>
                total +
                chapter.parts.reduce(
                    (partTotal, part) => partTotal + part.durationSeconds,
                    0
                ),
            0
        )

        const durationInMinutes =
            totalDurationSeconds > 60
                ? convertSecondsToMinutes(totalDurationSeconds)
                : 1

        const totalDurationText = intl.formatMessage(
            {
                defaultMessage: '{time} minutes',
                description: 'The total duration of minutes'
            },
            { time: durationInMinutes }
        )

        const formatDescription = `${languageText} • ${resourceType}`

        return {
            id: book.id,
            title: book.title,
            subtitle: book.subtitle,
            bookFormat: resourceType,
            formatDescription,
            imageUrl: book.imageUrl,
            authors: book.authors,
            audioChapters,
            totalDurationText,
            chapterCountText,
            resourceUrl: book.resourceUrl || null
        }
    }, [])

    return transform
}

export const useTransformAudioBooks = () => {
    const intl = useIntl()

    const transformEpisode = useTransformAudioBookEpisode()

    const transform = useCallback(
        (audioBooks: AudioBookChapter): TransformedAudioBooks => {
            const chapterNumberText = intl.formatMessage(
                {
                    defaultMessage: 'Chapter {chapterNumber}',
                    description:
                        'The book Chapter - followed by the chapter number. For example: Chapter 4'
                },
                { chapterNumber: audioBooks.number }
            )

            const chapterText = `${chapterNumberText} - ${audioBooks.title}`

            const audioBookProgress = audioBooks.progress || undefined

            const isCompleted = audioBookProgress?.isCompleted || false

            const progressText = isCompleted
                ? intl.formatMessage(intlMessages.completed)
                : audioBookProgress &&
                  audioBookProgress?.numberOfCompletedItems > 0
                ? `${audioBookProgress.numberOfCompletedItems} / ${audioBookProgress.totalNumberOfItems}`
                : undefined

            const parts = (
                (audioBooks?.parts || []) as TransformedAudioBookEpisode[]
            ).map((episodes) => transformEpisode(episodes, chapterText))

            return {
                title: chapterText,
                progress: audioBookProgress,
                isCompleted,
                progressText,
                number: audioBooks?.number,
                parts
            }
        },
        [intl]
    )

    return transform
}

export const useTransformAudioBookEpisode = () => {
    const intl = useIntl()

    const transform = useCallback(
        (
            episode: AudioBookChapterPart,
            chapterTitle?: string
        ): TransformedAudioBookEpisode => {
            const {
                hasCompleted = false,
                inProgress = false,
                progressInPercent = 0,
                progressInSeconds = 0,
                updatedAt,
                totalInSeconds = 0
            } = episode.contentStatus || {}

            const progress = episode.contentStatus
                ? {
                      hasCompleted,
                      inProgress,
                      isSoftComplete:
                          progressInPercent >= MEDIA_SOFT_COMPLETE_PERCENTAGE,
                      progressInPercent,
                      progressInSeconds: progressInSeconds as number,
                      updatedAt: new Date(updatedAt),
                      totalInSeconds: totalInSeconds as number
                  }
                : null

            let progressText = ''

            if (
                progress &&
                !progress.hasCompleted &&
                progress.progressInPercent > 0
            ) {
                const remainingSeconds =
                    progress.totalInSeconds - progress.progressInSeconds

                const remainingMinutes =
                    convertSecondsToMinutes(remainingSeconds)

                progressText = intl.formatMessage(
                    {
                        defaultMessage: '{remaining} min left',
                        description:
                            'The remaining time in minutes. For example: 20 min left'
                    },
                    { remaining: remainingMinutes }
                )
            }

            if (progress && progress.hasCompleted)
                progressText = intl.formatMessage(intlMessages.completed)

            const partText = intl.formatMessage(
                {
                    defaultMessage: 'Part {partText}',
                    description: 'The chapter part number. For example: Part 4'
                },
                { partText: episode.number }
            )

            const durationSeconds = episode?.durationSeconds || 0

            return {
                key: episode.id,
                id: episode.id,
                title: chapterTitle || '',
                partText,
                audioUrl: episode.audioUrl,
                number: episode.number ?? 0,
                durationSeconds,
                contentType: episode.contentType || undefined,
                progress,
                progressText
            }
        },
        [intl]
    )

    return transform
}
