import {
    MySuccessStory,
    MySuccessStoryInput,
    useSaveSuccessStoryMutation
} from '../../graphql/generated/autogenerated'
import { useCallback } from 'react'
import { ApolloError } from '@apollo/client'

export type UseSaveSuccessStoryResult = {
    successStory: MySuccessStory
    saveSuccessStory: (input: MySuccessStoryInput) => void
    isSaving: boolean
    error: Error | ApolloError | null
    isPristine: boolean
}

export function useSaveSuccessStory(): UseSaveSuccessStoryResult {
    const [save, { data, loading, error = null, called }] =
        useSaveSuccessStoryMutation()

    const saveSuccessStory = useCallback(
        (input: MySuccessStoryInput) => {
            save({
                variables: {
                    input
                }
            })
        },
        [save]
    )

    const successStory =
        (data?.content?.successStory?.saveMySuccessStory as MySuccessStory) ??
        null

    return {
        saveSuccessStory,
        successStory,
        error,
        isSaving: loading,
        isPristine: !called
    }
}
