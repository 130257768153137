import { Element } from 'html-react-parser'

const CMS_TODO_LIST_CLASS_NAME = 'todo-list'
const CMS_TODO_LIST_ITEM_LABEL_CLASS_NAME = 'todo-list__label'
const CMS_TODO_LIST_ITEM_LABEL_DESCRIPTION_CLASS_NAME =
    'todo-list__label__description'

const CMS_TABLE_CONTAINER_CLASS_NAME = 'table'

export const isCmsTodoList = (domNode: Element) => {
    return (
        domNode.name === 'ul' &&
        domNode.attribs?.class?.includes(CMS_TODO_LIST_CLASS_NAME)
    )
}

export const isCmsTodoListLabel = (domNode: Element) => {
    return (
        domNode.name === 'label' &&
        domNode.attribs?.class?.includes(CMS_TODO_LIST_ITEM_LABEL_CLASS_NAME)
    )
}

export const isCmsTodoListLabelDescription = (domNode: Element) => {
    return domNode.attribs?.class?.includes(
        CMS_TODO_LIST_ITEM_LABEL_DESCRIPTION_CLASS_NAME
    )
}

export const isCmsTableContainer = (domNode: Element) => {
    return (
        domNode.name === 'figure' &&
        domNode.attribs?.class?.includes(CMS_TABLE_CONTAINER_CLASS_NAME)
    )
}

const thriveVideoMatcher = /^https:\/\/video\.thriveglobal\.com\/(\w+)/
export const getThriveVideoIdFromEmbeddedTag = (domNode: Element) => {
    const urlMatches = domNode.attribs.url?.match(thriveVideoMatcher)

    const thriveVideoId = urlMatches?.[1]

    return thriveVideoId
}
