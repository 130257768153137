import { useCallback } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    BlurbV2,
    Contributor,
    CourseResource,
    CourseV2,
    LearnContentStatusProgressType,
    LearnContentStatusType,
    LessonV2,
    ModuleV2
} from '../../graphql/generated/autogenerated'
import { contentTypeString, getContentTypeIcon } from '../../utils'
import { PLACEHOLDER_IMAGE } from '../../utils/constants'
import { useTransformResource } from '../resource'
import {
    TransformedCourse,
    TransformedCourseBlurb,
    TransformedCourseContributor,
    TransformedCourseLesson,
    TransformedCourseModule,
    TransformedCourseModuleStatus,
    TransformedCourses
} from './types'

const defaultImage = PLACEHOLDER_IMAGE

const intlMessages = defineMessages({
    course: {
        defaultMessage: 'Course',
        description: 'Course - is a series of lessons in a particular subject'
    },
    of: {
        defaultMessage: 'of',
        description: `Expressing the relationship between two things for example 1 'of' 2.`
    },
    min: {
        defaultMessage: 'min',
        description: `Abrreviation for time in minutes.`
    },
    completed: {
        defaultMessage: 'Completed',
        description: `Something has been completed`
    }
})

export const useTransformCourses = () => {
    const intl = useIntl()

    const transform = useCallback(
        (course: CourseV2): TransformedCourses => {
            const isComplete = !!course?.progress?.isCompleted

            const numberOfItems = course?.progress?.totalNumberOfItems
            const numberOfCompletedItems =
                course?.progress?.numberOfCompletedItems

            const typeName = contentTypeString(
                intl,
                LearnContentStatusType.Course
            )

            const modulesProgressText = `${numberOfCompletedItems} ${intl.formatMessage(
                intlMessages.of
            )} ${numberOfItems}`

            const countText = `${numberOfItems} ${intl.formatMessage(
                {
                    defaultMessage:
                        '{numberOfItems, plural, =0 {Items} one {Item} other {Items}}',
                    description: `The number of modules of learning in a course.`
                },
                {
                    numberOfItems
                }
            )}`

            const progressText = numberOfCompletedItems
                ? `${modulesProgressText} ${intl.formatMessage(
                      {
                          defaultMessage:
                              '{numberOfItems, plural, =0 {Items} one {Item} other {Items}}',
                          description: `The number of modules of learning in a course.`
                      },
                      {
                          numberOfItems
                      }
                  )}`
                : countText

            return {
                id: course?.id,
                title: course?.title,
                subTitle: course?.subTitle,
                progressInPercent: course?.progress?.progressInPercent,
                isComplete,
                progressText,
                thumbnail: course?.thumbnail || defaultImage,
                icon: getContentTypeIcon(LearnContentStatusType.Course),
                typeName
            }
        },
        [intl]
    )

    return transform
}

export const useTransformCourse = () => {
    const intl = useIntl()

    const transformCourseLesson = useTransformCourseLesson()
    const transformCourseContributor = useTransformCourseContributor()
    const transformCourseBlurb = useTransformCourseBlurb()
    const transformCourseResources = useTransformResource()

    const transform = useCallback(
        (course: CourseV2): TransformedCourse => {
            const lessons = ((course?.lessons || []) as LessonV2[])
                .map(transformCourseLesson)
                .sort((a, b) => {
                    return a.order - b.order
                })

            const modules = lessons
                .reduce(
                    (currentModules, lesson) => [
                        ...currentModules,
                        ...lesson.modules.sort((a, b) => a.order - b.order)
                    ],
                    []
                )
                // add the order of the module in the course
                .map((module, index) => {
                    module.orderInCourse = index + 1

                    return module
                })
                // add the next module to the current module
                .map((module, index, modules) => {
                    module.next = modules[index + 1] || null
                    return module
                })

            const lessonResources = lessons.reduce(
                (currentResources, lesson) => [
                    ...currentResources,
                    ...lesson.resources
                ],
                []
            )

            const recentModules = [...modules].sort((a, b) => {
                if (a.status === null && b.status === null) return 0

                if (a.status !== null && b.status === null) return -1

                if (a.status === null && b.status !== null) return 1

                if (
                    a.status.updatedAt === null ||
                    b.status.updatedAt === null
                ) {
                    return 0
                }

                return b.status.updatedAt
                    .toISOString()
                    .localeCompare(a.status.updatedAt.toISOString())
            })

            const numberOfModules = modules.length
            const numberOfLessons = lessons.length
            const numberOfLessonResources = lessonResources.length

            const numberOfUnits = numberOfModules + numberOfLessonResources

            const numberOfCompletedModules = modules.filter(
                ({ isComplete }) => isComplete
            ).length

            const numberOfInProgressModules = modules.filter(
                ({ inProgress }) => inProgress
            ).length

            const numberOfCompletedLessons = modules.filter(
                ({ isComplete }) => isComplete
            ).length

            const numberOfInProgressLessons = modules.filter(
                ({ inProgress }) => inProgress
            ).length

            const numberOfCompletedLessonResources = lessonResources.filter(
                ({ isComplete }) => isComplete
            ).length

            const numberOfCompletedModulesAndResources =
                numberOfCompletedLessonResources + numberOfCompletedModules

            const modulesProgressText = `${numberOfCompletedModulesAndResources} ${intl.formatMessage(
                intlMessages.of
            )} ${numberOfUnits}`

            const completeLessonsFraction = `${numberOfCompletedLessons}/${numberOfLessons}`

            const countText = `${numberOfUnits} ${intl.formatMessage(
                {
                    defaultMessage:
                        '{numberOfUnits, plural, =0 {Items} one {Item} other {Items}}',
                    description: `The number of modules of learning in a course.`
                },
                {
                    numberOfUnits
                }
            )}`

            const isComplete = !lessons.some(({ isComplete }) => !isComplete)
            const inProgress = lessons.some(({ inProgress }) => inProgress)
            const isPristine = !lessons.some(({ isPristine }) => !isPristine)

            const progressInPercent = numberOfCompletedModulesAndResources
                ? Math.round(
                      (numberOfCompletedModulesAndResources / numberOfUnits) *
                          100
                  )
                : undefined

            const progressText = numberOfCompletedModulesAndResources
                ? `${modulesProgressText} ${intl.formatMessage(
                      {
                          defaultMessage:
                              '{numberOfUnits, plural, =0 {Items} one {Item} other {Items}}',
                          description: `The number of modules of learning in a course.`
                      },
                      {
                          numberOfUnits
                      }
                  )}`
                : countText

            const courseResources = (
                (course?.resources || []) as CourseResource[]
            )
                .filter((resource) => !!resource)
                .map((resource) => transformCourseResources(resource))

            return {
                id: course?.id || '',
                title: course?.title || '',
                typeName: intl.formatMessage(intlMessages.course),
                subTitle: course?.subTitle || '',
                about: course?.about || '',
                thumbnail: course?.thumbnail || defaultImage,
                numberOfModules,
                numberOfLessons,
                modulesProgressText,
                completeLessonsFraction,
                numberOfCompletedModules,
                numberOfInProgressModules,
                numberOfCompletedLessons,
                numberOfInProgressLessons,
                isComplete,
                inProgress,
                isPristine,
                lessons,
                modules,
                recentModules,
                countText,
                progressText,
                progressInPercent,
                lessonResources,
                experts: ((course?.experts || []) as Contributor[])
                    .filter((expert) => !!expert)
                    .map(transformCourseContributor),
                blurbs: ((course?.blurbs || []) as BlurbV2[])
                    .filter((blurb) => !!blurb)
                    .map(transformCourseBlurb),
                resources: courseResources
            }
        },
        [intl]
    )

    return transform
}

export const useTransformCourseLesson = () => {
    const transformCourseModule = useTransformCourseModule()
    const transformCourseResource = useTransformResource()

    const transform = useCallback(
        (lesson: LessonV2): TransformedCourseLesson => {
            const modules = ((lesson?.modules || []) as ModuleV2[])
                .map((module, index) =>
                    transformCourseModule(module, index, lesson.id)
                )
                .sort((a, b) => a.order - b.order)

            const resources = (
                (lesson?.resources || []) as CourseResource[]
            ).map((resource) => transformCourseResource(resource))

            const isComplete =
                !modules.some(({ isComplete }) => !isComplete) &&
                !resources.some(({ isComplete }) => !isComplete)

            const inProgress = modules.some(({ inProgress }) => inProgress)
            const isPristine = !modules.some(({ isPristine }) => !isPristine)

            const numberOfCompletedModules = modules.filter(
                ({ isComplete }) => isComplete
            ).length

            const numberOfInProgressModules = modules.filter(
                ({ inProgress }) => inProgress
            ).length

            const numberOfCompletedResources = resources.filter(
                ({ isComplete }) => isComplete
            ).length

            const numberOfModules = modules.length
            const numberOfResources = resources.length

            return {
                id: lesson?.id ? String(lesson.id) : '',
                title: lesson?.title || '',
                order: lesson?.order || 0,
                numberOfModules,
                numberOfResources,
                modules,
                resources,
                isComplete,
                inProgress,
                isPristine,
                numberOfCompletedResources,
                numberOfCompletedModules,
                numberOfInProgressModules
            }
        },
        []
    )

    return transform
}

export const useTransformCourseModule = () => {
    const intl = useIntl()

    const transform = useCallback(
        (
            module: ModuleV2,
            orderInCourse = 0,
            lessonId?: string
        ): TransformedCourseModule => {
            const contentId = module.id

            const contentStatus = module?.contentStatus || undefined

            let status: TransformedCourseModuleStatus = {
                progressInSeconds: 0,
                totalInSeconds: 0,
                totalInMinutes: 0,
                progressInPercent: 0,
                isComplete: false,
                inProgress: false,
                updatedAt: null,
                isPristine: true
            }

            if (contentStatus) {
                const {
                    progressInSeconds: progress = 0,
                    totalInSeconds: total = 0
                } = contentStatus

                const progressInSeconds = Math.round(Number(progress))
                const totalInSeconds = Math.round(Number(total))
                const totalInMinutes = Math.round(totalInSeconds / 60)
                const progressInPercent = Math.round(
                    (progressInSeconds / totalInSeconds) * 100
                )
                const updatedAt = new Date(contentStatus.updatedAt)
                const isComplete =
                    contentStatus.progressType ===
                    LearnContentStatusProgressType.Finished
                const inProgress =
                    contentStatus.progressType ===
                    LearnContentStatusProgressType.InProgress

                status = {
                    progressInSeconds,
                    totalInSeconds,
                    totalInMinutes,
                    progressInPercent,
                    isComplete,
                    inProgress,
                    updatedAt,
                    isPristine: false
                }
            }

            const durationText =
                status.totalInSeconds === 0
                    ? undefined
                    : `${intl.formatNumber(
                          status.totalInMinutes
                      )} ${intl.formatMessage(intlMessages.min)}`

            const videoLink = module?.videoLink
                ? module?.videoLink.split('https://video.thriveglobal.com/')[1]
                : ''

            const isComplete = status.isComplete
            const inProgress = status.inProgress
            const isPristine = status.isPristine
            const title = module.title
            const label = module.label
            const displayName = module.displayName

            return {
                id: String(module.id),
                lessonId: String(lessonId),
                displayName,
                byline: module.byline,
                title,
                label,
                thumbnail: module.thumbnail,
                order: module.order,
                orderInCourse,
                status,
                videoLink,
                type: module.type,
                isComplete,
                inProgress,
                isPristine,
                completePercentage: status.progressInPercent,
                next: null,
                orderLabel: displayName.split(':')[0] || '',
                durationText,
                contentId
            }
        },
        []
    )

    return transform
}

export const useTransformCourseBlurb = () => {
    const transform = useCallback((blurb: BlurbV2): TransformedCourseBlurb => {
        return {
            description: blurb?.description || ''
        }
    }, [])

    return transform
}

export const useTransformCourseContributor = () => {
    const transform = useCallback(
        (blurb: Contributor): TransformedCourseContributor => {
            return {
                name: blurb?.name || '',
                bio: blurb?.bio || '',
                photoUrl: blurb?.photoUrl || '',
                title: blurb?.title || '',
                id: blurb?.id || ''
            }
        },
        []
    )
    return transform
}
