import { CLOSED_CAPTION_LANGUAGE_CODES } from './constants'

export function useGetClosedCaptionCode(userLangCode?: string) {
    if (!userLangCode || userLangCode.startsWith('en')) {
        return 'en-US'
    }
    if (CLOSED_CAPTION_LANGUAGE_CODES.includes(userLangCode)) {
        return userLangCode
    }
    return userLangCode.split('-')[0]
}
