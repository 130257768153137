import { useCallback } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    LearnContentStatusType,
    Webinar,
    WebinarEvent,
    WebinarSession
} from '../../graphql/generated/autogenerated'
import { getContentTypeIcon } from '../../utils'
import {
    MEDIA_SOFT_COMPLETE_PERCENTAGE,
    PLACEHOLDER_IMAGE,
    THRIVE_VIDEO_DOMAIN
} from '../../utils/constants'
import { useTransformResource } from '../resource'
import { useUser } from '../user'
import {
    TransformedWebinar,
    TransformedWebinarEvent,
    TransformedWebinarSession
} from './types'

const intlMessages = defineMessages({
    webinar: {
        defaultMessage: 'Webinar',
        description: 'Webinar - a live video seminar'
    },
    liveNow: {
        defaultMessage: 'Live Now',
        description: `An event session is currently on-going`
    },
    completed: {
        defaultMessage: 'Completed',
        description: `Something has been completed`
    },
    registered: {
        defaultMessage: 'Registered',
        description: `The user has been registered for the webinar`
    },
    startingSoon: {
        defaultMessage: 'Starting Soon',
        description: 'An event session is about to start'
    },
    starts: {
        defaultMessage: 'Starts {time}',
        description: 'The time something starts'
    },
    progress: {
        defaultMessage:
            '{numberOfCompletedSessions} of {totalNumberOfSessions} {totalNumberOfSessions, plural, =0 { Sessions} one { Session} other { Sessions}}',
        description: 'The number of sessions'
    },
    percentage: {
        defaultMessage: '{percentage}%',
        description: 'A number with a percentage symbol. For example 80%'
    }
})

export const useTransformWebinar = () => {
    const intl = useIntl()

    const transformEvent = useTransformWebinarEvent()

    const transform = useCallback((webinar: Webinar): TransformedWebinar => {
        const numberOfEvents = webinar.events.length

        const transformedWebinar: TransformedWebinar = {
            id: webinar.id,
            title: webinar.title,
            typeName: intl.formatMessage(intlMessages.webinar),
            imageUrl: webinar.imageUrl ?? PLACEHOLDER_IMAGE,
            description: webinar.description ?? null,
            events: [],
            upNextEvent: null,
            isLive: false,
            isDoorOpen: false,
            isAttending: false,
            numberOfEvents,
            numberOfCompletedEvents: 0,
            progressInPercent: 0,
            progressText: '',
            statusText: '',
            icon: getContentTypeIcon(LearnContentStatusType.Webinar),
            isPristine: true,
            isComplete: false,
            inProgress: false
        }

        let events = webinar.events.map((event) => transformEvent(event))

        events = events.map((event, index, events) => {
            event.webinar = transformedWebinar

            event.previous = events[index - 1] ?? null
            event.next = events[index + 1] ?? null

            if (
                (event.previous?.isComplete ?? true) &&
                !event.isComplete &&
                event.sessions.some(
                    (session) =>
                        session.startTime.getTime() > new Date().getTime() ||
                        session.endTime.getTime() > new Date().getTime()
                )
            ) {
                event.isUpNext = true
            }

            return event
        })

        const isLive = events.some((event) => event.isLive)
        const isDoorOpen = events.some((event) => event.isDoorOpen)
        const isComplete = events.every((event) => event.isComplete)
        const isAttending = events.some((event) => event.isAttending)

        const upNextEvent = events.find((event) => event.isUpNext) ?? null

        const upNextSession = upNextEvent?.defaultSession ?? null

        const numberOfCompletedEvents = events.filter(
            ({ isComplete }) => isComplete
        ).length

        const progressInPercent = Math.round(
            (numberOfCompletedEvents / numberOfEvents) * 100
        )

        const isPristine = progressInPercent === 0

        const progressText =
            upNextSession?.startDateTimeText ??
            `${intl.formatMessage(intlMessages.progress, {
                numberOfCompletedSessions: numberOfCompletedEvents,
                totalNumberOfSessions: numberOfEvents
            })}`

        let statusText = intl.formatMessage(intlMessages.percentage, {
            percentage: progressInPercent
        })

        if (isPristine) {
            statusText = intl.formatMessage(intlMessages.registered)
        }

        if (upNextSession?.isStartingSoon) {
            statusText = intl.formatMessage(intlMessages.startingSoon)
        }

        if (upNextSession?.isLive) {
            statusText = intl.formatMessage(intlMessages.liveNow)
        }

        if (isComplete) {
            statusText = intl.formatMessage(intlMessages.completed)
        }

        transformedWebinar.isLive = isLive
        transformedWebinar.isDoorOpen = isDoorOpen
        transformedWebinar.events = events
        transformedWebinar.numberOfCompletedEvents = numberOfCompletedEvents
        transformedWebinar.progressInPercent = progressInPercent
        transformedWebinar.upNextEvent = upNextEvent
        transformedWebinar.isAttending = isAttending
        transformedWebinar.progressText = progressText
        transformedWebinar.isPristine = isPristine
        transformedWebinar.isComplete = isComplete
        transformedWebinar.inProgress = !isComplete
        transformedWebinar.statusText = statusText

        return transformedWebinar
    }, [])

    return transform
}

export const useTransformWebinarEvent = () => {
    const transformSession = useTransformWebinarSession()
    const transformResource = useTransformResource()

    const transform = useCallback(
        (event: WebinarEvent): TransformedWebinarEvent => {
            const transformedWebinarEvent: TransformedWebinarEvent = {
                id: event.id,
                title: event.title,
                subtitle: event.subtitle ?? null,
                imageUrl: event.imageUrl ?? PLACEHOLDER_IMAGE,
                defaultSession: null,
                sessions: [],
                tasks: [],
                isLive: false,
                isDoorOpen: false,
                isComplete: false,
                isAttending: false,
                isUpNext: false,
                next: null,
                previous: null,
                webinar: null
            }

            let sessions = event.sessions.map((session) =>
                transformSession(session)
            )

            sessions.sort((a, b) =>
                a.startTime
                    .toISOString()
                    .localeCompare(b.startTime.toISOString())
            )

            sessions = sessions.map((session, index, sessions) => {
                session.event = transformedWebinarEvent
                session.previous = sessions[index - 1] ?? null
                session.next = sessions[index + 1] ?? null
                return session
            })

            let defaultSession = sessions.find((session) => session.hasAttended)

            if (!defaultSession)
                defaultSession = sessions.find(
                    (session) => session.hasWatchedVideo
                )
            if (!defaultSession)
                defaultSession = sessions.find(
                    (session) => session.isWatchingVideo
                )
            if (!defaultSession)
                defaultSession = sessions.find((session) => session.isDoorOpen)
            if (!defaultSession)
                defaultSession = sessions.find((session) => session.inFuture)
            if (!defaultSession) defaultSession = sessions[0]

            const isLive = sessions.some((session) => session.isLive)
            const isDoorOpen = sessions.some((session) => session.isDoorOpen)
            const isComplete = sessions.some((session) => session.isComplete)
            const isAttending = sessions.some((session) => session.isAttending)

            const tasks = event.tasks.map((task) => ({
                ...transformResource(task),
                event: transformedWebinarEvent
            }))

            transformedWebinarEvent.sessions = sessions
            transformedWebinarEvent.isLive = isLive
            transformedWebinarEvent.isDoorOpen = isDoorOpen
            transformedWebinarEvent.isComplete = isComplete
            transformedWebinarEvent.tasks = tasks
            transformedWebinarEvent.isAttending = isAttending
            transformedWebinarEvent.defaultSession = defaultSession ?? null

            return transformedWebinarEvent
        },
        []
    )

    return transform
}

export const useTransformWebinarSession = () => {
    const intl = useIntl()

    const {
        email = '',
        userId = '',
        firstName = '',
        lastName = ''
    } = useUser() ?? {}

    const transform = useCallback(
        (session: WebinarSession): TransformedWebinarSession => {
            const isWatchingVideo =
                session?.webinarSessionProgress?.inProgress ?? false

            const hasAttended =
                session?.webinarSessionProgress?.hasAttended ?? false

            const hasWatchedVideo =
                session?.webinarSessionProgress?.hasCompleted ?? false

            const videoProgressInSeconds =
                session?.webinarSessionProgress?.progressInSeconds ?? 0

            const videoProgressInPercent =
                session?.webinarSessionProgress?.progressInPercent ?? 0

            const videoDurationInSeconds =
                session?.webinarSessionProgress?.durationInSeconds ?? 0

            const videoResumeTimeInSeconds =
                videoProgressInSeconds >= MEDIA_SOFT_COMPLETE_PERCENTAGE
                    ? 0
                    : videoProgressInSeconds

            const isComplete = hasAttended || hasWatchedVideo

            const startTime = new Date(session.startTime)
            const endTime = new Date(session.endTime)
            const now = new Date()
            const inFuture = startTime > now
            const inPast = endTime < now
            const isLive = now >= startTime && now <= endTime

            const doorOpenTime = new Date(startTime.getTime())
            doorOpenTime.setMinutes(startTime.getMinutes() - 10)

            const doorCloseTime = new Date(endTime.getTime())
            doorCloseTime.setMinutes(endTime.getMinutes() + 10)

            const isDoorOpen = doorOpenTime <= now && doorCloseTime >= now

            const isAttending = hasAttended && isDoorOpen

            const isEndingSoon = isDoorOpen && inPast
            const isStartingSoon = isDoorOpen && inFuture

            const startDateTimeText = `${intl.formatDate(startTime, {
                month: 'short',
                day: 'numeric',
                year: 'numeric'
            })} ${intl.formatTime(startTime, {
                hour: 'numeric',
                minute: 'numeric'
            })}`

            let statusText = intl.formatMessage(
                {
                    defaultMessage: 'Starting {time}',
                    description: 'The time an event starts'
                },
                { time: startDateTimeText }
            )

            if (isStartingSoon) {
                statusText = `${intl.formatMessage(intlMessages.startingSoon)}`
            }

            if (isLive) {
                statusText = `${intl.formatMessage(intlMessages.liveNow)}`
            }

            if (inPast) {
                statusText = startDateTimeText
            }

            let videoUrl = session.videoUrl || null

            if (videoUrl && videoUrl.startsWith(THRIVE_VIDEO_DOMAIN)) {
                videoUrl = videoUrl.split(`${THRIVE_VIDEO_DOMAIN}/`)[1]
            }

            let liveUrl = session.liveUrl
            // if the live URL is not for MS Teams - we include the default params
            if (!liveUrl.includes('teams.microsoft')) {
                liveUrl = `${liveUrl}?email=${email}&firstName=${firstName}&lastName=${lastName}&id=${userId}&userid=${userId}`
            }

            return {
                id: session.id,
                liveUrl,
                videoUrl,
                videoDurationInSeconds,
                videoProgressInPercent,
                videoProgressInSeconds,
                videoResumeTimeInSeconds,
                startTime,
                endTime,
                isWatchingVideo,
                hasAttended,
                isAttending,
                hasWatchedVideo,
                isComplete,
                inFuture,
                inPast,
                isLive,
                isDoorOpen,
                isStartingSoon,
                isEndingSoon,
                startDateTimeText,
                statusText,
                next: null,
                previous: null,
                event: null
            }
        },
        []
    )

    return transform
}
