import { Reset } from '@thriveglobal/thrive-web-leafkit'
import { ReactNode } from 'react'
import { LearnContentStatusType } from '../graphql/generated/autogenerated'

export type LearnContentStatusTypeWithComponent = Pick<
    typeof LearnContentStatusType,
    LearnContentStatusType.Reset
>
const contentStatusIconComponentMap: Record<
    keyof LearnContentStatusTypeWithComponent,
    ReactNode
> = {
    [LearnContentStatusType.Reset]: <Reset />
}

export default function getContentTypeIconComponent(
    contentType: keyof LearnContentStatusTypeWithComponent
) {
    return contentStatusIconComponentMap[contentType]
}
