export const inIframe = () => {
    return window.self !== window.top
}

export const inCypressIframe = () => {
    try {
        if (inIframe()) {
            const url = new URL(window.top?.location.href as string)
            return url.hash.startsWith('#/specs/runner')
        }

        return false
    } catch (e) {
        return false
    }
}

export const inWelcomeIframe = () => {
    try {
        if (inIframe()) {
            const url = new URL(window.top?.location.href as string)
            return url.hostname === 'app.experiencewelcome.com'
        }

        return false
    } catch (e) {
        return false
    }
}
