import { useCallback, useMemo } from 'react'
import {
    useRegisterForWebinarMutation,
    useGetWebinarLazyQuery,
    useGetWebinarsQuery,
    useUpdateWebinarVideoStatusMutation,
    useUpdateWebinarSessionStatusMutation,
    Webinar,
    LearnContentStatusProgressType
} from '../../graphql/generated/autogenerated'
import { useTransformWebinar } from './transformers'
import { TransformedWebinar } from './types'
import { ApolloError } from '@apollo/client'

export * from './transformers'
export * from './types'

export type UseFetchWebinarResult = {
    webinar: TransformedWebinar | null
    isLoading: boolean
    getWebinar: (id: string) => void
    notFound: boolean
    isPristine: boolean
    error: Error | ApolloError | null
}

export const useFetchWebinar = (): UseFetchWebinarResult => {
    const transformWebinar = useTransformWebinar()

    const [
        get,
        {
            loading,
            called,
            error = null,
            data = { learnV2: { getWebinar: null } }
        }
    ] = useGetWebinarLazyQuery({ errorPolicy: 'all' })

    const getWebinar = useCallback(
        (id: string) => {
            get({ variables: { webinarId: id } })
        },
        [get]
    )

    const webinar = useMemo(
        () =>
            data.learnV2?.getWebinar
                ? transformWebinar(data.learnV2?.getWebinar as Webinar)
                : null,
        [data.learnV2?.getWebinar]
    )

    return {
        webinar,
        isLoading: loading,
        getWebinar,
        notFound: called && !loading && webinar === null,
        isPristine: !called,
        error: error
    }
}

export const useFetchWebinars = ({ pageSize }: { pageSize?: number } = {}) => {
    const transformWebinar = useTransformWebinar()

    pageSize = pageSize || 10

    const {
        called,
        refetch: refetchPodcasts,
        loading,
        data,
        fetchMore: fetchMorePodcasts
    } = useGetWebinarsQuery({
        variables: { page: 1, pageSize },
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true
    })

    const hasMore = !!data?.learnV2?.getWebinars?.hasMore
    const currentPage = data?.learnV2?.getWebinars?.page || 1
    const nextPage = currentPage + 1

    const webinars = useMemo(
        () =>
            ((data?.learnV2?.getWebinars?.items || []) as Webinar[]).map(
                transformWebinar
            ),
        [data?.learnV2?.getWebinars?.items]
    )

    const noWebinars = called && !loading && webinars.length === 0

    const fetchMore = useCallback(() => {
        fetchMorePodcasts({ variables: { pageSize, page: nextPage } })
    }, [nextPage, pageSize, fetchMorePodcasts])

    const refetch = useCallback(() => {
        refetchPodcasts({ pageSize, page: 1 })
    }, [pageSize, refetchPodcasts])

    return {
        webinars,
        fetchMore,
        refetch,
        isFetchingInitial: loading && webinars.length === 0,
        isFetchingMore: loading && webinars.length > 0,
        hasMore,
        noWebinars
    }
}

export const useRegisterForWebinar: any = () => {
    const [registerForWebinar, { data, loading, error, called }] =
        useRegisterForWebinarMutation()

    const register = useCallback(
        (
            args: {
                id: string
            },
            refetchQueries: string[] = []
        ) => {
            registerForWebinar({
                variables: {
                    webinarId: args.id
                },
                refetchQueries
            })
        },
        [registerForWebinar]
    )

    const { isNewRegistration, webinar, isForbidden, isNotFound } =
        useMemo(() => {
            if (data?.learnV2?.registerForWebinar)
                return {
                    ...data.learnV2.registerForWebinar
                }

            return {
                isNewRegistration: false,
                webinar: null,
                isForbidden: false,
                isNotFound: false
            }
        }, [data])

    return {
        register,
        isPristine: !called,
        isNewRegistration,
        isForbidden,
        isNotFound,
        webinar,
        registrationError: error,
        registrationFailed: !!data && !webinar,
        isRegistering: loading
    }
}

export const useUpdateWebinarVideoStatus = () => {
    const [updateWebinarVideoStatus, { data, loading }] =
        useUpdateWebinarVideoStatusMutation()

    const update = useCallback(
        (
            args: {
                contentId: string
                progressInSeconds: number
                totalInSeconds: number
                isComplete: boolean
            },
            refetchQueries: string[] = []
        ) => {
            const { contentId, progressInSeconds, totalInSeconds, isComplete } =
                args

            const progressType =
                LearnContentStatusProgressType[
                    isComplete ? 'Finished' : 'InProgress'
                ]

            updateWebinarVideoStatus({
                variables: {
                    input: {
                        contentId,
                        progressType,
                        progressInSeconds: Math.round(progressInSeconds),
                        totalInSeconds: Math.round(totalInSeconds)
                    }
                },
                refetchQueries
            })
        },
        [updateWebinarVideoStatus]
    )

    const status = useMemo(() => {
        if (data?.learnV2?.updateWebinarVideoStatus)
            return {
                ...data.learnV2.updateWebinarVideoStatus
            }

        return null
    }, [data])

    return {
        update,
        status,
        isUpdating: loading
    }
}

export const useJoinWebinarSessionStatus = () => {
    const [updateWebinarSessionStatus, { data, loading }] =
        useUpdateWebinarSessionStatusMutation()

    const join = useCallback(
        (
            args: {
                sessionId: string
            },
            refetchQueries: string[] = []
        ) => {
            updateWebinarSessionStatus({
                variables: {
                    sessionId: args.sessionId
                },
                refetchQueries
            })
        },
        [updateWebinarSessionStatus]
    )

    const done = useMemo(() => {
        return data?.learnV2?.updateWebinarSessionStatus ?? false
    }, [data])

    return {
        join,
        done,
        isUpdating: loading
    }
}
