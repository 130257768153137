import React, { ComponentProps } from 'react'
import {
    CoursePage,
    ArticlePageV2,
    PodcastPage,
    LearnContentByTopicOutput,
    LearnContentItemPage,
    MySuccessStoryPage,
    BookPage,
    MicrostepPage
} from './graphql/generated/autogenerated'
import { ThriveApplication } from '@thriveglobal/thrive-web-core'

type ThriveInMemoryCacheConfig = Exclude<
    ComponentProps<typeof ThriveApplication>['apolloClientOptions'],
    undefined
>['cacheOptions']

export const cacheOptions: ThriveInMemoryCacheConfig = {
    typePolicies: {
        LearnQueryV2: {
            merge: true,
            fields: {
                getArticlesV2: {
                    keyArgs: ['pageSize', 'articleTypes'],
                    merge(
                        existing: ArticlePageV2 | undefined,
                        incoming: ArticlePageV2
                    ) {
                        return existing && incoming?.page !== 1
                            ? {
                                  ...incoming,
                                  items: [
                                      ...existing?.items,
                                      ...incoming?.items
                                  ]
                              }
                            : incoming
                    }
                },
                getPodcasts: {
                    keyArgs: ['pageSize'],
                    merge(
                        existing: PodcastPage | undefined,
                        incoming: PodcastPage
                    ) {
                        return existing && incoming?.page !== 1
                            ? {
                                  ...incoming,
                                  items: [
                                      ...existing?.items,
                                      ...incoming?.items
                                  ]
                              }
                            : incoming
                    }
                },
                getCourses: {
                    keyArgs: ['pageSize'],
                    merge(
                        existing: CoursePage | undefined,
                        incoming: CoursePage
                    ) {
                        return existing && incoming?.page !== 1
                            ? {
                                  ...incoming,
                                  items: [
                                      ...existing?.items,
                                      ...incoming?.items
                                  ]
                              }
                            : incoming
                    }
                },
                getBookmarkedItems: {
                    keyArgs: ['pageSize'],
                    merge(
                        existing: LearnContentItemPage | undefined,
                        incoming: LearnContentItemPage
                    ) {
                        return existing && incoming?.page !== 1
                            ? {
                                  ...incoming,
                                  items: [
                                      ...existing?.items,
                                      ...incoming?.items
                                  ]
                              }
                            : incoming
                    }
                }
            }
        },
        ThriveContentQuery: {
            merge: true,
            fields: {
                getContentByTopic: {
                    keyArgs: ['pageSize', 'topic'],
                    merge(
                        existing: LearnContentByTopicOutput | undefined,
                        incoming: LearnContentByTopicOutput
                    ) {
                        const incomingItem = incoming?.itemPage
                        const contentItemPageObject =
                            existing && incomingItem && incomingItem.page !== 1
                                ? {
                                      ...incoming,
                                      itemPage: {
                                          hasMore: incomingItem.hasMore,
                                          page: incomingItem.page,
                                          pageCount: incomingItem.pageCount,
                                          pageSize: incomingItem.pageSize,
                                          items: [
                                              ...existing?.itemPage.items,
                                              ...incomingItem.items
                                          ],
                                          __typename: incomingItem.__typename
                                      }
                                  }
                                : incoming
                        return contentItemPageObject
                    }
                }
            }
        },
        BookQuery: {
            merge: true,
            fields: {
                getBooks: {
                    keyArgs: ['pageSize'],
                    merge(existing: BookPage | undefined, incoming: BookPage) {
                        return existing && incoming?.page !== 1
                            ? {
                                  ...incoming,
                                  items: [
                                      ...existing?.items,
                                      ...incoming?.items
                                  ]
                              }
                            : incoming
                    }
                }
            }
        },
        SuccessStoryQuery: {
            merge: true,
            fields: {
                getMySuccessStories: {
                    keyArgs: ['pageSize'],
                    merge(
                        existing: MySuccessStoryPage | undefined,
                        incoming: MySuccessStoryPage
                    ) {
                        return existing && incoming?.page !== 1
                            ? {
                                  ...incoming,
                                  items: [
                                      ...existing?.items,
                                      ...incoming?.items
                                  ]
                              }
                            : incoming
                    }
                }
            }
        },
        LearnQuery: {
            merge: true
        },
        ContentQuery: {
            merge: true
        },
        JourneyQuery: {
            merge: true
        },
        TodayQuery: {
            merge: true,
            fields: {
                getPaginatedMicrosteps: {
                    keyArgs: ['pageSize', 'journeyIds'],
                    merge(
                        existing: MicrostepPage | undefined,
                        incoming: MicrostepPage
                    ) {
                        return existing && incoming?.page !== 1
                            ? {
                                  ...incoming,
                                  items: [
                                      ...existing?.items,
                                      ...incoming?.items
                                  ]
                              }
                            : incoming
                    }
                }
            }
        }
    }
}
