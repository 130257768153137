import { defineMessages, IntlShape } from 'react-intl'
import { LearnContentStatusType } from '../graphql/generated/autogenerated'

const intlMessages = defineMessages({
    article: {
        defaultMessage: 'Article',
        description: 'A piece of writing or publication on the web'
    },
    roleModel: {
        defaultMessage: 'Role Model',
        description: 'An article about an inspirational person'
    },
    podcast: {
        defaultMessage: 'Podcast',
        description: 'Podcast'
    },
    podcastEpisode: {
        defaultMessage: 'Podcast Episode',
        description: 'Podcast Episode'
    },
    course: {
        defaultMessage: 'Course',
        description: 'Course - is a series of lessons in a particular subject'
    },
    courseLesson: {
        defaultMessage: 'Course Lesson',
        description:
            'Course Lesson - a series of lessons that each contain course module videos'
    },
    courseModule: {
        defaultMessage: 'Course Module',
        description:
            'Course Module - is a series of course videos in a particular subject'
    },
    courseResource: {
        defaultMessage: 'Course Resource',
        description:
            'A resource - additional course material attached to the course'
    },
    recipe: {
        defaultMessage: 'Recipe',
        description:
            'Recipe - an article including a set of instructions for preparing a particular dish, including a list of the ingredients required.'
    },
    webinar: {
        defaultMessage: 'Webinar',
        description: 'Webinar - a live video seminar'
    },
    watchMeThrive: {
        defaultMessage: 'Watch Me Thrive',
        description:
            'Watch Me Thrive - an article demonstrating how someone has benefited from using Thrive'
    },
    journeyDailyStep: {
        defaultMessage: 'Daily Step',
        description:
            'Daily Step - a daily step is a task the user has to complete - an objective for that day'
    },
    reset: {
        defaultMessage: 'Reset',
        description: `Reset - Reset is the name of a content type - a short video`
    },
    audioBookChapter: {
        defaultMessage: 'Audio Book Chapter',
        description: `Audio Book Chapter - a audio file that is a part of the overall audio book`
    },
    microStep: {
        defaultMessage: 'Microstep',
        description: `Microstep - A daily step the user can check in to`
    },
    acuteCareResource: {
        defaultMessage: 'Acute Care Resource',
        description:
            'Acute Care Resource - an Acute Care Resource is an external resource the user can access'
    }
})

export function contentTypeString(
    intl: IntlShape,
    type: LearnContentStatusType
) {
    return {
        [LearnContentStatusType.Article]: intl.formatMessage(
            intlMessages.article
        ),
        [LearnContentStatusType.Recipe]: intl.formatMessage(
            intlMessages.recipe
        ),
        [LearnContentStatusType.RoleModel]: intl.formatMessage(
            intlMessages.roleModel
        ),
        [LearnContentStatusType.Podcast]: intl.formatMessage(
            intlMessages.podcast
        ),
        [LearnContentStatusType.PodcastEpisode]: intl.formatMessage(
            intlMessages.podcastEpisode
        ),
        [LearnContentStatusType.Course]: intl.formatMessage(
            intlMessages.course
        ),
        [LearnContentStatusType.CourseResource]: intl.formatMessage(
            intlMessages.courseResource
        ),
        [LearnContentStatusType.Module]: intl.formatMessage(
            intlMessages.courseModule
        ),
        [LearnContentStatusType.Webinar]: intl.formatMessage(
            intlMessages.webinar
        ),
        [LearnContentStatusType.WatchMeThrive]: intl.formatMessage(
            intlMessages.watchMeThrive
        ),
        [LearnContentStatusType.JourneyDailyStep]: intl.formatMessage(
            intlMessages.journeyDailyStep
        ),
        [LearnContentStatusType.AcuteCareResource]: intl.formatMessage(
            intlMessages.acuteCareResource
        ),
        [LearnContentStatusType.AudioBookChapterPart]: intl.formatMessage(
            intlMessages.audioBookChapter
        ),
        [LearnContentStatusType.Microstep]: intl.formatMessage(
            intlMessages.microStep
        ),
        [LearnContentStatusType.Lesson]: intl.formatMessage(
            intlMessages.courseLesson
        ),
        [LearnContentStatusType.Reset]: intl.formatMessage(intlMessages.reset)
    }[type]
}
