import { ArticleType } from '../graphql/generated/autogenerated'

export default function getArticleType(
    isRoleModel?: boolean,
    isRecipe?: boolean,
    isWatchMeThrive?: boolean
): ArticleType[] {
    if (isRoleModel) {
        return [ArticleType.RoleModel]
    } else if (isRecipe) {
        return [ArticleType.Recipe]
    } else if (isWatchMeThrive) {
        return [ArticleType.WatchMeThrive]
    } else {
        return [ArticleType.Article]
    }
}
