import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

const makePattern = (target: string) => {
    if (!target.startsWith('/')) {
        target = `/${target}`
    }

    if (!target.endsWith('$')) {
        target = `${target}$`
    }

    return new RegExp(target)
}

export type PageMessageInternal = {
    id: number
    visible: boolean
    shouldDelete: boolean
    shownInTarget: boolean
} & PageMessage

export interface PageMessage {
    type: 'error' | 'warning' | 'info' | 'success'
    content: ReactNode
    delayInSeconds: number
    timeoutInSeconds: number
    closable: boolean
    /** the learn url path (as Regex) the message should be displayed on */
    target: string
    persist: boolean
}

let lastPageMessageId = 0

export default function usePageMessage() {
    const { pathname } = useLocation()

    const [messages, setMessages] = useState<PageMessageInternal[]>([])

    const visibleMessages = useMemo(
        () => messages.filter(({ visible }) => visible),
        [messages]
    )

    const removeMessage = useCallback(({ id }: { id: number }) => {
        setMessages((messages) => {
            let updated = false

            const newMessages = messages.map((message) => {
                if (message.id === id) {
                    updated = true
                    return {
                        ...message,
                        shouldDelete: true
                    }
                }
                return message
            })

            return updated ? newMessages : messages
        })
    }, [])

    const addMessage = useCallback(
        (args: Partial<Omit<PageMessage, 'id'>>) => {
            const {
                type = 'info',
                content,
                timeoutInSeconds = 0,
                delayInSeconds = 0,
                closable = true,
                target = '*',
                persist = false
            } = args

            const id = ++lastPageMessageId

            const visible = delayInSeconds === 0

            setMessages((messages) => [
                ...messages,
                {
                    id,
                    type,
                    content,
                    closable,
                    target,
                    persist,
                    visible,
                    delayInSeconds,
                    timeoutInSeconds,
                    shouldDelete: false,
                    shownInTarget: makePattern(target).test(pathname)
                }
            ])

            if (!visible) {
                setTimeout(() => {
                    setMessages((messages) => {
                        return messages.map((message) => {
                            if (message.id === id)
                                return {
                                    ...message,
                                    visible: true
                                }
                            return message
                        })
                    })
                }, delayInSeconds * 1000)
            }

            if (timeoutInSeconds > 0) {
                setTimeout(() => {
                    removeMessage({ id })
                }, timeoutInSeconds * 1000)
            }

            return { id }
        },
        [removeMessage, pathname]
    )

    const clearMessages = useCallback(() => {
        setMessages([])
    }, [])

    useEffect(() => {
        setMessages((messages) => {
            const newMessages = messages.filter(
                (message) => !message.shouldDelete
            )

            if (newMessages.length === messages.length) {
                return messages
            } else {
                return newMessages
            }
        })
    }, [messages])

    useEffect(() => {
        setMessages((messages) => {
            return messages.map((message) => {
                const target = message.target
                let visible = message.visible
                let shouldDelete = message.shouldDelete
                let shownInTarget = message.shownInTarget

                const pattern = makePattern(target)

                if (!message.persist && shownInTarget) {
                    visible = false
                    shouldDelete = true
                } else {
                    if (!message.persist && !pattern.test(pathname)) {
                        visible = false
                    } else {
                        visible = pattern.test(pathname)
                    }
                }

                shownInTarget = pattern.test(pathname)

                return {
                    ...message,
                    shownInTarget,
                    shouldDelete,
                    visible
                }
            })
        })
    }, [pathname])

    return {
        messages: visibleMessages,
        addMessage,
        removeMessage,
        clearMessages
    }
}
