import { useSelector, RootStateOrAny } from 'react-redux'
import { User } from './types'

export * from './types'

export const useUser = (): User | null => {
    const user = useSelector((state: RootStateOrAny) => {
        if (state && state.user) {
            const {
                user: {
                    isLegacy,
                    email,
                    fullName,
                    companyId,
                    analyticsId,
                    userId
                } = {
                    isLegacy: undefined,
                    email: undefined,
                    fullName: '',
                    companyId: undefined,
                    analyticsId: undefined,
                    userId: undefined
                },
                settings: { locale = '' } = {}
            } = state

            const [firstName, lastName] = fullName?.split(' ') ?? []

            return {
                isLegacy,
                email,
                fullName,
                firstName,
                lastName,
                companyId,
                analyticsId,
                locale,
                userId
            }
        }

        return null
    })

    return user
}
