import { ApolloError } from '@apollo/client'
import { useCallback, useMemo } from 'react'
import {
    ArticleContentType,
    ArticleV2,
    LearnContentStatusProgressType,
    LearnContentStatusSubtype,
    LearnContentStatusType,
    useGetArticleLazyQuery,
    useGetArticlesQuery,
    useUpdateArticleLikesMutation,
    useUpdateArticleStatusMutation
} from '../../graphql/generated/autogenerated'
import { getArticleType } from '../../utils'
import { useTransformArticle } from './transformers'
import { TransformedArticle } from './types'
import { useAppSelector } from '@thriveglobal/thrive-web-core'

export * from './transformers'
export * from './types'

interface UseArticle {
    getArticle: (articleId: string, locale?: string) => void
    article: TransformedArticle | null
    isLoading: boolean
    isPristine: boolean
    articleNotFound: boolean
    error: Error | ApolloError | null
}

export const useArticle = (): UseArticle => {
    const transformArticle = useTransformArticle()
    const { locale: userLocale } = useAppSelector((state) => state.settings)

    const [
        get,
        {
            loading,
            called,
            error = null,
            data = { learnV2: { getArticleV2: null } }
        }
    ] = useGetArticleLazyQuery({
        errorPolicy: 'all'
    })

    const getArticle = useCallback(
        (articleId: string, locale?: string) => {
            get({
                variables: {
                    articleId,
                    localeStr: locale ?? userLocale
                }
            })
        },
        [get]
    )

    const article = useMemo(
        () =>
            data.learnV2?.getArticleV2
                ? transformArticle(data.learnV2?.getArticleV2 as ArticleV2)
                : null,
        [data.learnV2?.getArticleV2, transformArticle]
    )

    return {
        getArticle,
        article,
        isLoading: loading,
        isPristine: !called,
        articleNotFound: called && !loading && article === null,
        error
    }
}

interface UseArticles {
    articles: TransformedArticle[]
    noArticles: boolean
    hasMore: boolean
    isFetchingInitial: boolean
    isFetchingMore: boolean
    fetchMore: () => void
    error?: ApolloError
}

export const useArticles = ({
    isRoleModel,
    isRecipe,
    isWatchMeThrive,
    pageSize
}: {
    isRoleModel?: boolean
    isRecipe?: boolean
    isWatchMeThrive?: boolean
    pageSize?: number
} = {}): UseArticles => {
    const transformArticle = useTransformArticle()

    pageSize = pageSize || 10

    const {
        loading,
        data: articlesData,
        fetchMore: fetchMoreArticles,
        error
    } = useGetArticlesQuery({
        variables: {
            page: 1,
            pageSize,
            contentTypes: [ArticleContentType.Text, ArticleContentType.Video],
            articleTypes: getArticleType(isRoleModel, isRecipe, isWatchMeThrive)
        },
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true
    })

    const hasMore = !!articlesData?.learnV2?.getArticlesV2?.hasMore
    const currentPage = articlesData?.learnV2?.getArticlesV2?.page || 1
    const nextPage = currentPage + 1

    const articles = useMemo(
        () =>
            ((articlesData?.learnV2?.getArticlesV2?.items || []) as ArticleV2[])
                .filter((article) => !!article)
                .map(transformArticle),
        [articlesData?.learnV2?.getArticlesV2?.items, transformArticle]
    )

    const noArticles = !loading && articles.length === 0

    const fetchMore = useCallback(() => {
        fetchMoreArticles({ variables: { pageSize, page: nextPage } })
    }, [nextPage, pageSize, fetchMoreArticles])

    return {
        articles,
        fetchMore,
        isFetchingInitial: loading && articles.length === 0,
        isFetchingMore: loading && articles.length > 0,
        hasMore,
        noArticles,
        error
    }
}

export const useUpdateArticleStatus = () => {
    const [
        updateArticleStatus,
        { data: articleData, loading: isLoadingArticle }
    ] = useUpdateArticleStatusMutation()

    const update = useCallback(
        (
            args: {
                contentId: string
                progressInSeconds: number
                isComplete?: boolean
                articleType?: string
            },
            refetchQueries: string[] = []
        ) => {
            const { contentId, progressInSeconds, isComplete, articleType } =
                args

            const progressType =
                LearnContentStatusProgressType[
                    isComplete ? 'Finished' : 'InProgress'
                ]

            const subtype =
                LearnContentStatusSubtype[
                    articleType == ArticleContentType.Text ? 'Text' : 'Video'
                ]

            const articleData = {
                contentId,
                progressType,
                progressInSeconds: Math.round(progressInSeconds),
                subtype
            }

            updateArticleStatus({
                variables: {
                    input: articleData
                },
                refetchQueries
            })
        },
        [updateArticleStatus]
    )

    const progressStatus = useMemo(() => {
        if (articleData?.learnV2?.updateArticleStatus)
            return {
                ...articleData.learnV2.updateArticleStatus
            }

        return null
    }, [articleData])

    return {
        update,
        progressStatus,
        isUpdating: isLoadingArticle
    }
}

export const useHandleLikeClicked = () => {
    const [updateArticleLikes, { data: articleData, loading: isLoading }] =
        useUpdateArticleLikesMutation()

    const markAsLiked = useCallback(
        (
            args: {
                contentId: string
                contentType: LearnContentStatusType
                contentSubtype: LearnContentStatusSubtype
                likes: number
            },
            refetchQueries: string[] = []
        ) => {
            const { contentId, contentType, contentSubtype, likes } = args

            const articleData = {
                contentId,
                contentType,
                contentSubtype,
                likes
            }

            updateArticleLikes({
                variables: {
                    input: articleData
                },
                refetchQueries
            })
        },
        [updateArticleLikes]
    )

    const contentLikesStatus = useMemo(() => {
        if (articleData?.learnV2?.updateContentLikes)
            return {
                ...articleData.learnV2.updateContentLikes
            }

        return null
    }, [articleData])

    return {
        markAsLiked,
        contentLikesStatus,
        isUpdating: isLoading
    }
}
