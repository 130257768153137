import {
    MySuccessStory,
    useGetMySuccessStoryLazyQuery
} from '../../graphql/generated/autogenerated'
import { ApolloError } from '@apollo/client'
import { useCallback } from 'react'

export type UseMySuccessStoryResult = {
    successStory: MySuccessStory | null
    getSuccessStory: (successStoryId: string) => void
    isLoading: boolean
    error: Error | ApolloError | null
    notFound: boolean
    isPristine: boolean
}

export function useMySuccessStory(): UseMySuccessStoryResult {
    const [get, { loading, called, error = null, data }] =
        useGetMySuccessStoryLazyQuery({
            errorPolicy: 'all'
        })

    const getSuccessStory = useCallback(
        (successStoryId: string) => {
            get({
                variables: {
                    getMySuccessStoryId: successStoryId
                }
            })
        },
        [get]
    )

    const successStory =
        (data?.content?.successStory?.getMySuccessStory as MySuccessStory) ??
        null

    return {
        successStory,
        getSuccessStory,
        isLoading: loading,
        notFound: called && !loading && successStory === null,
        isPristine: !called,
        error
    }
}
