import React, { memo, useEffect, Suspense, lazy } from 'react'
import { Box, DialogContent } from '@mui/material'
import {
    LeafDialog,
    ThriveFullscreenLoading,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { withSuccessStoryFormErrorProvider } from './SuccessStoryFormErrorProvider'
import {
    LearnContentStatusType,
    MySuccessStoryInput,
    SuccessStoryFeedbackType
} from '../../../../graphql/generated/autogenerated'

const SuccessStoryDialogContent = lazy(
    () => import('./SuccessStoryDialogContent')
)

export type SuccessStoryDialogProps = {
    onDialogOpened?: () => void
    onClose: () => void
    onSuccessStorySaved?: (data: MySuccessStoryInput) => void
    onSuccessStoryPrivacyChanged?: (isPublic: boolean) => void
    onImagesUploaded?: () => void
    open: boolean
    successStoryType: SuccessStoryFeedbackType
    successStoryContentType: LearnContentStatusType | null
    successStoryContentId: string | null
}

function SuccessStoryDialog(props: SuccessStoryDialogProps) {
    const {
        open,
        onClose,
        onSuccessStorySaved,
        onSuccessStoryPrivacyChanged,
        onImagesUploaded,
        onDialogOpened,
        successStoryType,
        successStoryContentType,
        successStoryContentId
    } = props

    const theme = useTheme()

    useEffect(() => {
        if (open) {
            onDialogOpened?.()
        }
    }, [onDialogOpened, open])

    return (
        <LeafDialog
            open={open}
            onClose={onClose}
            fullScreen
            PaperProps={{
                sx: {
                    backgroundColor: theme.palette.background.paper
                }
            }}
            data-testid="success-story-dialog"
        >
            <DialogContent>
                <Box
                    sx={{
                        px: theme.spacing(17),
                        pb: theme.spacing(12),
                        [theme.breakpoints.down('md')]: {
                            px: theme.spacing(3),
                            pt: theme.spacing(3),
                            pb: theme.spacing(3)
                        },
                        [theme.breakpoints.down('sm')]: {
                            p: 0
                        }
                    }}
                >
                    {open && (
                        <Suspense fallback={<ThriveFullscreenLoading />}>
                            <SuccessStoryDialogContent
                                successStoryType={successStoryType}
                                successStoryContentType={
                                    successStoryContentType
                                }
                                successStoryContentId={successStoryContentId}
                                onDialogClose={onClose}
                                onSuccessStorySaved={onSuccessStorySaved}
                                onSuccessStoryPrivacyChanged={
                                    onSuccessStoryPrivacyChanged
                                }
                                onSuccessStoryImagesUploaded={onImagesUploaded}
                            />
                        </Suspense>
                    )}
                </Box>
            </DialogContent>
        </LeafDialog>
    )
}

export default memo(withSuccessStoryFormErrorProvider(SuccessStoryDialog))
