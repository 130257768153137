import { useCallback } from 'react'
import {
    TransformedContent,
    TransformedContentItem,
    ContentType
} from './types'
import { ContentTypeEnum } from './enums'
import {
    LearnContentItem,
    LearnContentStatusType
} from '../../graphql/generated/autogenerated'
import { useIntl } from 'react-intl'
import {
    contentTypeString,
    durationTextString,
    paramFilter,
    PLACEHOLDER_IMAGE
} from '../../utils'
import { ROUTES } from '../../routes'
import { IconName } from '@fortawesome/fontawesome-common-types'
import getContentTypeIconComponent, {
    LearnContentStatusTypeWithComponent
} from '../../utils/getContentTypeIconComponent'

const defaultImage = PLACEHOLDER_IMAGE

export function isArticleType(contentType: LearnContentStatusType) {
    return [
        LearnContentStatusType.Article,
        LearnContentStatusType.RoleModel,
        LearnContentStatusType.Recipe,
        LearnContentStatusType.WatchMeThrive
    ].includes(contentType)
}

export const useTransformBrowseByTopic = () => {
    const transform = useCallback(
        (content: ContentType): TransformedContent => {
            let id = ''
            let typeName = ''
            let topicParam = ''
            let image = ''
            let backgroundColor = ''
            let description = ''

            if (content?.__typename === ContentTypeEnum.PulseDimension) {
                typeName = ContentTypeEnum.PulseDimension
                topicParam = content?.type
            }

            if (content?.__typename === ContentTypeEnum.Journey) {
                topicParam = content?.id
                typeName = ContentTypeEnum.Journey
                id = content?.id
                image = content?.thumbnailUrl
                backgroundColor = content?.colorPalette.main
                description = content?.description ?? ''
            }

            return {
                id,
                name: content?.name,
                description,
                topicParam,
                typeName,
                image,
                backgroundColor
            }
        },
        []
    )

    return transform
}

export const useTransformBrowseByTopicItem = () => {
    const intl = useIntl()

    const transform = useCallback(
        (content: LearnContentItem): TransformedContentItem => {
            let typeIcon: IconName = 'align-left'
            let url = ''
            let parentId = ''
            let subParentId = ''

            if (isArticleType(content?.contentType)) {
                url = `${ROUTES.ARTICLES}/${content?.id}${paramFilter(
                    content?.contentType
                )}`
            }

            if (
                content?.contentType ===
                    LearnContentStatusType.PodcastEpisode &&
                content?.parent?.__typename === 'PodcastEpisodeParent'
            ) {
                parentId = content?.parent?.podcastID
                subParentId = content?.parent?.seasonID
                url = `${ROUTES.PODCASTS}/${parentId}?season=${subParentId}&episode=${content?.id}`
            }

            if (content?.contentType === LearnContentStatusType.Webinar) {
                url = `${ROUTES.WEBINAR}/${content?.id}`
            }

            if (content?.contentType === LearnContentStatusType.Course) {
                url = `${ROUTES.COURSES}/${content?.id}`
            }

            if (content?.contentType === LearnContentStatusType.Reset) {
                url = `${ROUTES.RESET}/thrive/${content?.id}`
            }

            if (
                content?.contentType === LearnContentStatusType.Module &&
                content?.parent?.__typename === 'CourseModuleParent'
            ) {
                parentId = content?.parent?.courseID
                url = `${ROUTES.COURSES}/${parentId}?module=${content?.id}`
            }

            // content type card icon
            if (content?.iconName) {
                typeIcon = content?.iconName as IconName
            } else {
                typeIcon = 'align-left'
            }

            const statusText = content?.durationInSeconds
                ? durationTextString(
                      content.durationInSeconds,
                      intl,
                      content.contentSubtype
                  )
                : undefined

            const contentStatus = content?.contentStatus || undefined
            const typeName = contentTypeString(intl, content.contentType)

            return {
                id: content?.id,
                title: content?.title,
                imageUrl: content?.imageUrl || defaultImage,
                durationInSeconds: content?.durationInSeconds || 0,
                contentType: content?.contentType,
                subType: content?.contentSubtype,
                typeName,
                typeIcon,
                IconComponent: getContentTypeIconComponent(
                    typeName as keyof LearnContentStatusTypeWithComponent
                ),
                url,
                statusText,
                contentStatus
            }
        },
        []
    )

    return transform
}
