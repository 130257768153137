import { useIntl, defineMessages } from 'react-intl'
import { useCallback } from 'react'
import {
    TransformedPodcast,
    TransformedPodcasts,
    TransformedPodcastSeason,
    TransformedPodcastEpisode
} from './types'
import {
    Podcast,
    PodcastSeason,
    PodcastEpisode,
    LearnContentStatusType
} from '../../graphql/generated/autogenerated'
import percent from '../../utils/percent'
import {
    contentTypeString,
    getContentTypeIcon,
    PLACEHOLDER_IMAGE,
    MEDIA_SOFT_COMPLETE_PERCENTAGE
} from '../../utils'

const defaultImage = PLACEHOLDER_IMAGE
const intlMessages = defineMessages({
    podcast: {
        defaultMessage: 'Podcast',
        description: 'Podcast'
    },
    s: {
        defaultMessage: 'S',
        description: `Abbreviation for the first leter of the word 'Season' of a podcast`
    },
    seasons: {
        defaultMessage:
            '{numberOfSeasons, plural, =0 {Seasons} one {Season} other {Seasons}}',
        description: `The seasons in a podcast`
    },
    ep: {
        defaultMessage: 'EP',
        description: `Abbreviation for the first two leters of the word 'Episode' of a podcast`
    },
    episodes: {
        defaultMessage:
            '{numberOfEpisodes, plural, =0 {Episodes} one {Episode} other {Episodes}}',
        description: `The episodes of a podcast`
    },
    min: {
        defaultMessage: 'min',
        description: `Abrreviation for time in minutes.`
    },
    left: {
        defaultMessage: 'left',
        description: `The amount of something remaining for example 20 oranges 'left'`
    },
    completed: {
        defaultMessage: 'completed',
        description: `To have completed a task`
    }
})

export const useTransformPodcasts = () => {
    const intl = useIntl()

    const transform = useCallback(
        (podcast: Podcast): TransformedPodcasts => {
            const isComplete = !!podcast?.progress?.isCompleted

            const typeName = contentTypeString(
                intl,
                LearnContentStatusType.Podcast
            )

            const episodesProgressText = isComplete
                ? intl.formatMessage(intlMessages.completed)
                : `
            ${intl.formatNumber(podcast.numberOfSeasons)}  ${intl.formatMessage(
                      intlMessages.seasons,
                      {
                          numberOfSeasons: podcast.numberOfSeasons
                      }
                  )} • ${intl.formatNumber(
                      podcast.numberOfEpisodes
                  )} ${intl.formatMessage(intlMessages.episodes, {
                      numberOfEpisodes: podcast.numberOfEpisodes
                  })}`

            return {
                id: podcast?.id,
                title: podcast?.title,
                progressInPercent: podcast?.progress?.progressInPercent,
                isComplete,
                progressText: episodesProgressText,
                thumbnail: podcast?.imageUrl || defaultImage,
                typeName
            }
        },
        [intl]
    )

    return transform
}

export const useTransformPodcast = () => {
    const intl = useIntl()

    const transformSeason = useTransformPodcastSeason()

    const transform = useCallback(
        (podcast: Podcast): TransformedPodcast => {
            const initialTransformedPodcast: TransformedPodcast = {
                id: podcast.id,
                title: podcast.title,
                typeName: intl.formatMessage(intlMessages.podcast),
                summary: podcast.summary || ' ',
                imageUrl: podcast?.imageUrl || defaultImage,
                numberOfEpisodes: podcast.numberOfEpisodes,
                numberOfSeasons: podcast.numberOfSeasons,
                seasons: [],
                seasonsSortedByNewest: [],
                numberOfEpisodesInProgress: 0,
                percentageOfEpisodesInProgress: 0,
                numberOfEpisodesPreviouslyCompleted:
                    podcast.podcastProgress
                        ?.numberOfEpisodesPreviouslyCompleted,
                percentageOfEpisodesPreviouslyCompleted: 0,
                icon: getContentTypeIcon(LearnContentStatusType.Podcast),
                hasCompleted: true,
                isSoftComplete: true,
                inProgress: false,
                isPristine: true,
                episodes: [],
                countText: `
                ${intl.formatNumber(
                    podcast.numberOfSeasons
                )}  ${intl.formatMessage(intlMessages.seasons, {
                    numberOfSeasons: podcast.numberOfSeasons
                })} • ${intl.formatNumber(
                    podcast.numberOfEpisodes
                )} ${intl.formatMessage(intlMessages.episodes, {
                    numberOfEpisodes: podcast.numberOfEpisodes
                })}`,
                hasCompletedWithout: () => false
            }
            const seasons = podcast.seasons.map((season) =>
                transformSeason(season, initialTransformedPodcast)
            )
            const seasonsSortedByNewest = seasons.slice().reverse()

            const episodes = seasons.reduce(
                (episodes, season) => [...episodes, ...season.episodes],
                []
            )

            seasons.forEach((season) => {
                initialTransformedPodcast.numberOfEpisodesInProgress +=
                    season.numberOfEpisodesInProgress

                if (!season.hasCompleted) {
                    initialTransformedPodcast.hasCompleted = false
                }

                if (!season.isSoftComplete) {
                    initialTransformedPodcast.isSoftComplete = false
                }

                if (season.inProgress) {
                    initialTransformedPodcast.isPristine = false
                    initialTransformedPodcast.inProgress = true
                }
            })

            initialTransformedPodcast.seasons = seasons
            initialTransformedPodcast.episodes = episodes
            initialTransformedPodcast.seasonsSortedByNewest =
                seasonsSortedByNewest

            initialTransformedPodcast.percentageOfEpisodesInProgress = percent(
                initialTransformedPodcast.numberOfEpisodesInProgress,
                initialTransformedPodcast.numberOfEpisodes
            )

            initialTransformedPodcast.percentageOfEpisodesPreviouslyCompleted =
                percent(
                    initialTransformedPodcast.numberOfEpisodesPreviouslyCompleted,
                    initialTransformedPodcast.numberOfEpisodes
                )

            initialTransformedPodcast.hasCompletedWithout = (
                episodeOrIds: Array<string | TransformedPodcastEpisode>
            ) => {
                const episodeIds = episodeOrIds.map((episodeOrId) =>
                    typeof episodeOrId === 'string'
                        ? episodeOrId
                        : episodeOrId.id
                )

                return !episodes
                    .filter(({ id }) => !episodeIds.includes(id))
                    .some(({ progress }) => !progress || !progress.hasCompleted)
            }

            return {
                ...initialTransformedPodcast
            }
        },
        [intl]
    )

    return transform
}

export const useTransformPodcastSeason = () => {
    const intl = useIntl()

    const transformEpisode = useTransformPodcastEpisode()

    const transform = useCallback(
        (
            season: PodcastSeason,
            podcast: TransformedPodcast
        ): TransformedPodcastSeason => {
            const initialTransformedSeason: TransformedPodcastSeason = {
                id: season.id,
                title: season.title,
                summary: season.summary,
                number: season.number,
                numberOfEpisodes: season.numberOfEpisodes,
                podcast,
                episodes: [],
                episodesSortedByNewest: [],
                numberOfEpisodesInProgress: 0,
                percentageOfEpisodesInProgress: 0,
                hasCompleted: true,
                isSoftComplete: true,
                inProgress: false,
                isPristine: true,
                hasCompletedWithout: () => false
            }

            const episodes = season.episodes.map((episode) =>
                transformEpisode(episode, initialTransformedSeason)
            )

            const episodesSortedByNewest = episodes
                .slice()
                .sort((a, b) => b.number - a.number)

            episodes.forEach((episode) => {
                if (episode.progress === null) {
                    initialTransformedSeason.hasCompleted = false
                    initialTransformedSeason.isSoftComplete = false
                } else {
                    initialTransformedSeason.isPristine = false

                    if (episode.progress.inProgress) {
                        initialTransformedSeason.inProgress = true
                        initialTransformedSeason.numberOfEpisodesInProgress++
                    }

                    if (!episode.progress.hasCompleted)
                        initialTransformedSeason.hasCompleted = false
                    if (!episode.progress.isSoftComplete)
                        initialTransformedSeason.hasCompleted = false
                }
            })

            initialTransformedSeason.episodes = episodes
            initialTransformedSeason.episodesSortedByNewest =
                episodesSortedByNewest

            initialTransformedSeason.percentageOfEpisodesInProgress = percent(
                initialTransformedSeason.numberOfEpisodesInProgress,
                initialTransformedSeason.numberOfEpisodes
            )

            initialTransformedSeason.hasCompletedWithout = (
                episodeOrIds: Array<string | TransformedPodcastEpisode>
            ) => {
                const episodeIds = episodeOrIds.map((episodeOrId) =>
                    typeof episodeOrId === 'string'
                        ? episodeOrId
                        : episodeOrId.id
                )

                return !episodes
                    .filter(({ id }) => !episodeIds.includes(id))
                    .some(({ progress }) => !progress || !progress.hasCompleted)
            }

            return {
                ...initialTransformedSeason
            }
        },
        [intl]
    )

    return transform
}

export const useTransformPodcastEpisode = () => {
    const intl = useIntl()

    const transform = useCallback(
        (
            episode: PodcastEpisode,
            season: TransformedPodcastSeason
        ): TransformedPodcastEpisode => {
            const {
                hasCompleted = false,
                durationInSeconds = 0,
                inProgress = false,
                progressInPercent = 0,
                progressInSeconds = 0,
                updatedAt
            } = episode.podcastEpisodeProgress || {}

            const progress = episode.podcastEpisodeProgress
                ? {
                      hasCompleted,
                      isSoftComplete:
                          progressInPercent >= MEDIA_SOFT_COMPLETE_PERCENTAGE,
                      inProgress,
                      progressInPercent,
                      durationInSeconds: durationInSeconds as number,
                      progressInSeconds: progressInSeconds as number,
                      durationInMinutesText: `${intl.formatNumber(
                          Math.round((durationInSeconds as number) / 60)
                      )} ${intl.formatMessage(intlMessages.min)}`,
                      durationLeftInMinutesText: `${intl.formatNumber(
                          Math.round(
                              ((durationInSeconds as number) -
                                  (progressInSeconds as number)) /
                                  60
                          )
                      )} ${intl.formatMessage(
                          intlMessages.min
                      )} ${intl.formatMessage(intlMessages.left)}`,
                      updatedAt: new Date(updatedAt)
                  }
                : null

            let progressText = ''

            if (progress) progressText = progress.durationLeftInMinutesText
            if (progress && progress.hasCompleted)
                progressText = intl.formatMessage(intlMessages.completed)

            return {
                id: episode.id,
                title: episode.title,
                summary: episode.summary,
                number: episode.number,
                podcastTitle: episode.podcast?.title || episode.title,
                podcastId: episode.podcastId || '',
                imageUrl: episode.imageUrl || defaultImage,
                audioUrl: episode.audioUrl || null,
                albumTitle: `${episode.podcast?.title} • ${intl.formatMessage(
                    intlMessages.s
                )}${intl.formatNumber(season?.number)} : ${intl.formatMessage(
                    intlMessages.ep
                )}${intl.formatNumber(episode.number)}`,
                icon: getContentTypeIcon(LearnContentStatusType.PodcastEpisode),
                season,
                progress,
                progressText,
                isLiked: episode?.likes?.isLiked,
                totalNumberOfLikes: episode?.likes?.totalNumberOfLikes,
                transcriptUrl: episode.transcriptUrl || ''
            }
        },
        [intl]
    )

    return transform
}
