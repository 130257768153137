import { IconName } from '@fortawesome/fontawesome-common-types'
import { useCallback } from 'react'
import { defineMessages, IntlShape, useIntl } from 'react-intl'
import {
    ArticleAuthor,
    ArticleContentType,
    ArticleV2,
    LearnContentStatusProgressType,
    LearnContentStatusSubtype,
    LearnContentStatusType
} from '../../graphql/generated/autogenerated'
import { ROUTES } from '../../routes'
import {
    contentTypeString,
    getContentTypeIcon,
    PLACEHOLDER_IMAGE
} from '../../utils'
import { convertSecondsToMinutes } from '../../utils/convert-seconds-to-minutes'
import { ArticleType } from './enums'
import { RelatedArticleFragment, TransformedArticle } from './types'

const defaultImage = PLACEHOLDER_IMAGE

const intlMessages = defineMessages({
    text: {
        defaultMessage: 'min read',
        description:
            'The amount of time in minutes (abbreviated) estimated to read an article'
    },
    video: {
        defaultMessage: 'min watch',
        description:
            'The duration of time in minutes (abbreviated) to watch a video article'
    },
    completed: {
        defaultMessage: 'Completed',
        description:
            'The Article is completed - the user has read the entire article'
    }
})

function checkIsComplete(articleProgressType: LearnContentStatusProgressType) {
    return articleProgressType === LearnContentStatusProgressType.Finished
}

export function makeDurationInMinutesText(
    durationInSeconds: number,
    intl: IntlShape,
    type: ArticleType
) {
    const durationInMinutes =
        durationInSeconds > 60 ? convertSecondsToMinutes(durationInSeconds) : 1
    return `${intl.formatNumber(durationInMinutes)} ${
        {
            [ArticleType.Text]: intl.formatMessage(intlMessages.text),
            [ArticleType.Video]: intl.formatMessage(intlMessages.video)
        }[type]
    }`
}

export const useTransformArticle = () => {
    const intl = useIntl()

    const transformRelatedArticles = useTransformRelatedArticles()

    const transform = useCallback(
        (article: ArticleV2): TransformedArticle => {
            const body = article.body || ''
            const authors: ArticleAuthor[] | undefined = article.authors

            let type: ArticleType = ArticleType.Text
            let contentSubtype: LearnContentStatusSubtype =
                LearnContentStatusSubtype.Text
            let source = ''
            let icon: IconName = 'newspaper'
            let cfSource: string | undefined = undefined
            let isMp4 = false

            if (article?.articleContentType === ArticleContentType.Text) {
                type = ArticleType.Text
            }

            if (article?.articleContentType === ArticleContentType.Video) {
                type = ArticleType.Video
                contentSubtype = LearnContentStatusSubtype.Video
                icon = 'video'
                source = article.source || ''
                cfSource = source.split('/')[3]
                isMp4 = source.toLowerCase().endsWith('.mp4')
            }

            if (article?.articleContentType === ArticleContentType.Audio) {
                contentSubtype = LearnContentStatusSubtype.Audio
            }

            const journeys = article.journeys?.map((journey) => ({
                name: journey.shortName || journey.name,
                id: journey.id
            }))

            const subscriptionJourneys =
                article.userJourneys?.subscriptionJourneys.map((journey) => ({
                    name: journey.shortName || journey.name,
                    id: journey.id
                }))

            const uniqueJourneyNames = Array.from(
                new Set(journeys?.map((journey) => journey.name))
            )

            const durationInMinutesText = makeDurationInMinutesText(
                article.durationSeconds,
                intl,
                type
            )

            const isComplete = article?.contentStatus?.progressType
                ? checkIsComplete(article.contentStatus.progressType)
                : false

            const inProgress =
                article?.contentStatus?.progressType ===
                LearnContentStatusProgressType.InProgress

            const completedText = isComplete
                ? `${intl.formatMessage(intlMessages.completed)}`
                : undefined

            const bookmarked = article.bookmarked

            const typeName = contentTypeString(intl, article?.contentType)

            const typeFilter = article?.contentType?.toLowerCase()

            const isRoleModel =
                article?.contentType === LearnContentStatusType.RoleModel

            const articleUrl = `${ROUTES.ARTICLES}/${
                article?.id
            }?filter=${typeFilter}${
                isRoleModel ? `&locale=${article?.language}` : ''
            }`

            icon = getContentTypeIcon(
                article.contentType,
                article.articleContentType
            )

            return {
                id: article?.id,
                journeys,
                subscriptionJourneys,
                uniqueJourneyNames,
                title: article?.title,
                typeName,
                contentType: article?.contentType,
                contentSubtype: contentSubtype,
                summary: article?.summary || '',
                imageUrl: article?.imageUrl || defaultImage,
                language: article.language || '',
                durationInMinutesText,
                readStatus: completedText || durationInMinutesText,
                type,
                typeFilter,
                articleContentType: article?.articleContentType,
                icon,
                source,
                body,
                cfSource,
                isMp4,
                authors,
                isComplete,
                inProgress,
                bookmarked,
                isLiked: article?.likes?.isLiked,
                articleUrl,
                totalNumberOfLikes: article?.likes?.totalNumberOfLikes,
                relatedArticles: (
                    (article?.relatedArticles || []) as ArticleV2[]
                )
                    .filter((relatedArticles) => !!relatedArticles)
                    .map(transformRelatedArticles),
                hasStatus: isComplete || inProgress
            }
        },
        [intl]
    )

    return transform
}

export const useTransformRelatedArticles = () => {
    const intl = useIntl()

    const transform = useCallback(
        (relatedArticle: ArticleV2): RelatedArticleFragment => {
            let type: ArticleType = ArticleType.Text
            let icon: IconName = 'newspaper'

            if (
                relatedArticle?.articleContentType === ArticleContentType.Text
            ) {
                type = ArticleType.Text
            }
            if (
                relatedArticle?.articleContentType === ArticleContentType.Video
            ) {
                type = ArticleType.Video
                icon = 'video'
            }

            const isComplete = relatedArticle?.contentStatus?.progressType
                ? checkIsComplete(relatedArticle.contentStatus.progressType)
                : false

            const durationInMinutesText = makeDurationInMinutesText(
                relatedArticle.durationSeconds,
                intl,
                type
            )

            const completedText = isComplete
                ? `${intl.formatMessage(intlMessages.completed)}`
                : undefined

            const typeName = contentTypeString(
                intl,
                relatedArticle?.contentType
            )

            icon = getContentTypeIcon(
                relatedArticle.contentType,
                relatedArticle.articleContentType
            )

            return {
                id: relatedArticle.id,
                title: relatedArticle.title,
                typeName,
                contentType: relatedArticle.contentType,
                imageUrl: relatedArticle.imageUrl || defaultImage,
                readStatus: completedText || durationInMinutesText,
                icon,
                type,
                isComplete
            }
        },
        []
    )

    return transform
}
