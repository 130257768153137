export enum ROUTES {
    OVERVIEW = '/learn',
    COURSE = '/learn/courses/:courseId',
    COURSES = '/learn/courses',
    ARTICLE = '/learn/articles/:articleId',
    ARTICLES = '/learn/articles',
    BROWSE = '/learn/browse',
    BROWSE_TOPIC = '/learn/browse/:topicType/:topicId',
    PODCAST = '/learn/podcasts/:podcastId',
    PODCASTS = '/learn/podcasts',
    COMPANY_RESOURCES = '/learn/resources',
    WEBINAR = '/learn/webinars/:webinarId',
    WEBINAR_REGISTRATION = '/learn/webinars/:webinarId/registration',
    BOOK = '/learn/books/:bookId',
    BOOKS = '/learn/books',
    WEBINARS = '/learn/webinars',
    BOOKMARKS = '/learn/bookmarks',
    SUCCESS_STORIES = '/learn/success-stories',
    SUCCESS_STORY = '/learn/success-stories/:successStoryId',
    ACTIVITY = '/learn/activity',
    SEARCH = '/search',
    RESET = '/reset',
    MANAGE_MICROSTEPS = '/today/microsteps'
}
