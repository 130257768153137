import { useCallback, useMemo } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { ApolloError } from '@apollo/client'
import {
    ArticleContentType,
    LearnContentStatus,
    LearnContentStatusType,
    RecommendationType,
    UserRecommendationsQuery,
    useUserRecommendationsQuery
} from '../../graphql/generated/autogenerated'
import {
    contentTypeString,
    durationTextString,
    paramFilter,
    PLACEHOLDER_IMAGE
} from '../../utils'
import { ROUTES } from '../../routes'
import { NUMBER_OF_RECOMMENDATIONS } from '../../constants'
import { TransformedContentItem } from '../browse'

interface CommonRecommendationFields {
    id: string
    title: string
    contentStatus?: LearnContentStatus
}

type ArticleV2 = CommonRecommendationFields &
    Pick<
        Extract<
            UserRecommendationsQuery['recommendation']['userRecommendations'][number],
            { __typename: 'ArticleV2' }
        >,
        | 'imageUrl'
        | 'durationSeconds'
        | 'contentType'
        | 'articleContentType'
        | '__typename'
    >

type ModuleV2 = CommonRecommendationFields &
    Pick<
        Extract<
            UserRecommendationsQuery['recommendation']['userRecommendations'][number],
            { __typename: 'ModuleV2' }
        >,
        'thumbnail' | 'courseID' | '__typename'
    >

type PodcastEpisode = CommonRecommendationFields &
    Pick<
        Extract<
            UserRecommendationsQuery['recommendation']['userRecommendations'][number],
            { __typename: 'PodcastEpisode' }
        >,
        'imageUrl' | 'podcastId' | 'seasonId' | '__typename'
    >

type UserRecommendationItem = ArticleV2 | ModuleV2 | PodcastEpisode | null

const isArticleV2 = (item: UserRecommendationItem): item is ArticleV2 =>
    item?.__typename === 'ArticleV2'

const isModuleV2 = (item: UserRecommendationItem): item is ModuleV2 =>
    item?.__typename === 'ModuleV2'

const isPodcastEpisode = (
    item: UserRecommendationItem
): item is PodcastEpisode => item?.__typename === 'PodcastEpisode'

// TODO: Will be removed when icons names got migrated to CMS
const iconByArticleContentType = {
    [ArticleContentType.Audio]: 'headphones',
    [ArticleContentType.Video]: 'video',
    default: 'newspaper'
}

const iconByArticleType = {
    [LearnContentStatusType.Recipe]: 'pot-food',
    [LearnContentStatusType.RoleModel]: 'trophy',
    default: 'newspaper'
}

const transformItem = (
    item: ArticleV2 | ModuleV2 | PodcastEpisode,
    intl: IntlShape
) => {
    const commonFields: CommonRecommendationFields = {
        id: item.id,
        title: item.title,
        contentStatus: item.contentStatus
    }

    if (isArticleV2(item)) {
        const typeIcon =
            item.contentType === ArticleContentType.Article
                ? iconByArticleContentType[item.articleContentType] ||
                  iconByArticleContentType.default
                : iconByArticleType[item.contentType] ||
                  iconByArticleType.default

        return {
            ...commonFields,
            imageUrl: item?.imageUrl || PLACEHOLDER_IMAGE,
            durationInSeconds: item.durationSeconds || 0,
            contentType: item.contentType,
            subType: item.articleContentType,
            url: `${ROUTES.ARTICLES}/${item.id}${paramFilter(
                item.contentType
            )}`,
            typeName: contentTypeString(intl, item.contentType),
            statusText: item?.durationSeconds
                ? durationTextString(
                      item.durationSeconds,
                      intl,
                      item.articleContentType
                  )
                : undefined,
            typeIcon: typeIcon
        }
    }
    if (isModuleV2(item)) {
        return {
            ...commonFields,
            imageUrl: item.thumbnail || PLACEHOLDER_IMAGE,
            url: `${ROUTES.COURSES}/${item.courseID}?module=${item.id}`,
            typeName: contentTypeString(intl, LearnContentStatusType.Module),
            statusText: item?.contentStatus?.progressInPercent
                ? `${item.contentStatus.progressInPercent}%`
                : undefined,
            typeIcon: 'graduation-cap'
        }
    }
    if (isPodcastEpisode(item)) {
        return {
            ...commonFields,
            imageUrl: item.imageUrl || PLACEHOLDER_IMAGE,
            url: `${ROUTES.PODCASTS}/${item.podcastId}?season=${item.seasonId}&episode=${item.id}`,
            typeName: contentTypeString(
                intl,
                LearnContentStatusType.PodcastEpisode
            ),
            contentStatus: item.contentStatus,
            typeIcon: 'headphones'
        }
    }
}

const getResponseData = (data?: UserRecommendationsQuery) =>
    data?.recommendation?.userRecommendations.filter((item) => item) ?? []

const useUserRecommendations = (): {
    data: TransformedContentItem[]
    loading: boolean
    error: ApolloError | null
    refetch: () => void
} => {
    const intl = useIntl()
    const {
        loading,
        data,
        error,
        refetch: refetchRecommendations
    } = useUserRecommendationsQuery({
        variables: {
            filter: {
                requestedRecommendationsTotal: NUMBER_OF_RECOMMENDATIONS,
                recommendationTypes: [
                    RecommendationType.Article,
                    RecommendationType.CourseModule,
                    RecommendationType.PodcastEpisode,
                    RecommendationType.Recipe,
                    RecommendationType.RoleModel
                ]
            }
        },
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true
    })

    const transformedData = useMemo(
        () =>
            getResponseData(data).reduce<TransformedContentItem[]>(
                (
                    acc: TransformedContentItem[],
                    item: UserRecommendationItem
                ) => {
                    if (
                        isArticleV2(item) ||
                        isModuleV2(item) ||
                        isPodcastEpisode(item)
                    ) {
                        const transformedItem = transformItem(item, intl)
                        if (transformedItem) {
                            acc.push(transformedItem as TransformedContentItem)
                        }
                    }
                    return acc
                },
                []
            ),
        [data, intl]
    )

    const refetch = useCallback(
        () => refetchRecommendations(),
        [refetchRecommendations]
    )

    return {
        data: transformedData,
        loading,
        error: error as ApolloError,
        refetch
    }
}

export default useUserRecommendations
