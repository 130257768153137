import { ReactNode, createContext, useMemo, useContext } from 'react'
import usePageMessage from './page-message'
import { useGoToPage } from './go-to-page'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { useUser } from '../data'
import { useGetClosedCaptionCode } from './use-get-closed-caption-code'

export type IPageContext = {
    isSmallScreen: boolean
    user: ReturnType<typeof useUser>
    isEnUsLang: boolean
    closedCaptionLangCode: string
} & ReturnType<typeof usePageMessage> &
    ReturnType<typeof useGoToPage>

export const PageContext = createContext<IPageContext>({
    isSmallScreen: false,

    user: null,
    isEnUsLang: false,
    closedCaptionLangCode: 'en-US',
    messages: [],
    addMessage: () => ({ id: 0 }),
    removeMessage: () => undefined,
    clearMessages: () => undefined,

    goToHomePage: () => undefined,
    goToWebinarPage: () => undefined,
    goToWebinarsPage: () => undefined,
    goToArticlePage: () => undefined,
    goToArticlesPage: () => undefined,
    goToBooksPage: () => undefined,
    goToSuccessStoriesPage: () => undefined,
    goToLink: () => undefined
})

export const usePageContext = () => {
    return useContext(PageContext)
}

export const PageProvider = ({ children }: { children: ReactNode }) => {
    const pageMessage = usePageMessage()

    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const user = useUser()
    const goToPage = useGoToPage()

    const closedCaptionLangCode = useGetClosedCaptionCode(user?.locale)

    const isEnUsLang = closedCaptionLangCode.startsWith('en')

    const context = useMemo(() => {
        return {
            user,
            isEnUsLang,
            closedCaptionLangCode,
            isSmallScreen,
            ...pageMessage,
            ...goToPage
        }
    }, [
        pageMessage,
        goToPage,
        isSmallScreen,
        user,
        isEnUsLang,
        closedCaptionLangCode
    ])

    return (
        <PageContext.Provider value={context}>{children}</PageContext.Provider>
    )
}
