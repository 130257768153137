export const getSessionStorageItem = <T>(key: string): T | null => {
    const storedItem = sessionStorage.getItem(key)
    return storedItem ? JSON.parse(storedItem) : null
}

export const setSessionStorageItem = <T>(key: string, value: T): void => {
    sessionStorage.setItem(key, JSON.stringify(value))
}

export const removeSessionStorageItem = (key: string): void => {
    sessionStorage.removeItem(key)
}
